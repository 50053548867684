import React, { useEffect, useRef, useState } from "react";
import Header from "../../Header/header";
import HeaderMenu from "../../Header/header-menu";
import Footer from "../../Footer/Footer";
import Isi from "../../ISI/Isi";
import './OffPeriods.css';
import NavigationBar from "../../NavigationBar/NavigationBar";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import IsiFrame from '../../ISI/Isi-frame';
import { Helmet } from 'react-helmet';
import MainLayout from "../../Layouts/Main_Layout";

export default function OffPeriods() {
  const experienceOff = useRef(null);
  const location = useLocation();
  const [activePd, setActivePD] = useState(false);
  const [activeEx, setActiveEx] = useState(false);
  const [show, setShow] = useState(true)
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const handleImageLoads = () => {
    // Check if all images have finished loading
    const allImagesLoaded = Array.from(document.querySelectorAll('img')).every((image) => image.complete);

    if (allImagesLoaded) {
      setImagesLoaded(true);
    }
  };

  function handleScrollCSS() {
    if (document.getElementById('what-are-off-periods-page')) {
      var element1 = document.getElementById('experiencing-off');
      var offsetTop1 = element1.offsetTop - 100;
      if (window.scrollY > offsetTop1) {
        document.getElementById('pd-return').classList.add('submenu-style-active');
      }
      else {
        document.getElementById('pd-return').classList.remove('submenu-style-active');
        document.getElementById('pd-return').classList.add('submenu-style-not-active');
      }
      var element2 = document.getElementById('returnS');

      var offsetTop2 = element2.offsetTop - 100;
      if (window.scrollY > offsetTop2) {
        document.getElementById('exp-link').classList.add('submenu-style-active');
      }
      if (window.scrollY > offsetTop1 || window.scrollY < offsetTop2) {
        document.getElementById('exp-link').classList.remove('submenu-style-active');
        document.getElementById('exp-link').classList.add('submenu-style-not-active');
      }
    }
  }
  const handleVideoClick = (clickClass) => {
    window.gtag('event', 'patient_video', {
      'event_category': 'video_start',
      'event_label': clickClass,
      'page_location': window.location.href
    });
  }

  const getChromeVersion = () => {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    if (raw) {
      return parseInt(raw[2], 10);
    }
    else {
      return 100;
    }
  }

  useEffect(() => {
    document.addEventListener('click', function (e) {
      if (e.target.closest('a') || e.target.closest('button')) {
        if (document.location.pathname.includes('/what-are-off-periods')) {
          window.gtag('event', 'what_are_off_periods_buttons');
        }
        if (document.location.pathname.includes('/about-inbrija')) {
          window.gtag('event', 'about_inbrija_buttons');
        }
        if (document.location.pathname.includes('/how-to-use-inbrija')) {
          window.gtag('event', 'how_to_use_inbrija_buttons');
        }
        if (document.location.pathname.includes('/savings-and-support')) {
          window.gtag('event', 'savings_and_support_buttons');
        }
      }
    });
    handleScrollCSS();
    setTimeout(() => {
      if (location.hash === '#experiencing-off') {
        // experienceOff.current.scrollIntoView({ behavior: 'smooth', block: 'start', top:100 });
        var element = document.getElementById('experiencing-off');
        var headerOffset = 45;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        if (getChromeVersion() > 60) {
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }
        else {
          window.scrollTo(0, offsetPosition);
        }
        setActiveEx(true);
        setActivePD(false);
      }
    }, 500);
    setTimeout(() => {
      if (location.hash === '#return-symptoms') {
        var element = document.getElementById('returnS');
        var headerOffset = 0;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        if (getChromeVersion() > 60) {
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }
        else {
          window.scrollTo(0, offsetPosition);
        }
        setActivePD(true);
        setActiveEx(false);
      }
    }, 500)

    if (location.pathname === '/what-are-off-periods' || location.pathname === '/what-are-off-periods/') {
      var windowHeight = window.innerHeight;
      const submenu = document.getElementById('submenu');

      window.addEventListener('scroll', function () {

        var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollPosition > windowHeight) {
          submenu.classList.add('sticky');
        }
        else {
          submenu.classList.remove('sticky');
        }
      });
    }

    const calculateOffset = () => {
      const componentElement = myRef.current;

      if (myRef) {
        const offsetTop = componentElement.offsetTop;
        function handleScroll() {
          if (window.innerHeight + window.scrollY > offsetTop) {
            setShow(false);
          }
          else (
            setShow(true)
          )
        }

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("scroll", handleScrollCSS);


        return () => {
          window.removeEventListener("scroll", handleScroll);
          window.removeEventListener("scroll", handleScrollCSS);
        };
      }
    };

    calculateOffset();
    window.addEventListener('resize', calculateOffset);

    const handleHashChange = () => {
      // Reset the images loaded status
      setImagesLoaded(false);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('resize', calculateOffset);
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [location, imagesLoaded]);

  const navigate = useNavigate();
  const myRef = useRef(null)

  const executeScroll = () => {
    var element = myRef.current;
    var headerOffset = 39;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo(0, offsetPosition);
    setShow(true)
  }

  if (imagesLoaded) {
    var header = document.getElementById('submenu');
    window.addEventListener('scroll', function () {
      var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      const offsetTop = header.offsetTop;
      if (scrollPosition > offsetTop) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    });
  }

  return (
    <MainLayout>
      <Helmet>
        <title>What are Parkinson's OFF Periods? | INBRIJA® (levodopa inhalation powder)</title>
        <meta property='og:title' content={`What are Parkinson's OFF Periods? | INBRIJA® (levodopa inhalation powder)`} />
        <meta property='og:description' content={`Learn about the return of Parkinson's symptoms between doses of medication, sometimes referred to as OFF periods. See Indication, Important Safety Information, and Patient Information Leaflet.`} />
        <meta property='og:site_name' content='INBRIJA.COM' />
        <meta property='og:type' content='website' />
        <meta name='description' content={`Learn about the return of Parkinson's symptoms between doses of medication, sometimes referred to as OFF periods. See Indication, Important Safety Information, and Patient Information Leaflet.`} />
        <meta property="og:image" content="https://inbrija.com/assets/images/Inbrija-OG-Image.jpg" />
        <meta property='og:url' content='https://inbrija.com/what-are-off-periods' />
        <meta property='og:image:alt' content='INBRIJA logo' />
      </Helmet>
      <div className="what-are-off-periods" id="what-are-off-periods-page">
        <Header />
        <HeaderMenu>
          <div className="container-1500">
            <div className='hero-section'>
              <div className='hero-section-content'>
                <h1>What are <span>OFF periods</span>?</h1>
                <h4>A closer look at symptom return in Parkinson's disease (PD)</h4>
              </div>
              <div className='hero-section-image'>
                <img onLoad={handleImageLoads}
                  alt={`A woman holds a camera. She looks at something out of frame. The words on her camera say "Trembling but I'll fight for my image."`}
                  className='img-fluid off-period-hero-image'
                  src="/assets/website_images/Photographer 1.webp"
                />
                <div className="actor_portrayal">Actor portrayal.</div>
              </div>
            </div>
          </div>
        </HeaderMenu>
        <div className="menu-bar-sticky-desktop" id="submenu-div">
          <div className="brush-style-2" id="submenu">
            <div className="submenu-style">
              <Link className="text-decoration-none" to='/what-are-off-periods#return-symptoms' ><div onClick={() => { setActiveEx(false); setActivePD(true); }} id="exp-link" className={activePd ? 'submenu-style-active' : 'submenu-style-not-active'}>Return of PD Symptoms</div></Link>
              <Link className="text-decoration-none" to='/what-are-off-periods#experiencing-off' >   <div onClick={() => { setActiveEx(true); setActivePD(false); }} id="pd-return" className={activeEx ? 'submenu-style-active' : 'submenu-style-not-active'} >Experiencing OFF Periods</div></Link>
            </div>
          </div>
        </div>
        <div className="menu-bar-sticky-mobile">
          <div className="brush-style-2 mobile-header-sticky"></div>
        </div>
        <div className="container" id="returnS">
          <div className="pd-symptom-container" id="main-content">
            <h1>OFF periods can be disruptive</h1>
            <p className="">Many people with Parkinson’s experience symptom return (OFF&nbsp;periods)—and even one <b>OFF&nbsp;period</b> can really <br className="br-desktop" />disrupt things. Even when you take your Parkinson's medication regularly, symptoms can still return.</p>
            <div className="separator"></div>
            <h3>
              Symptoms that return can include motor symptoms (related to movement) or nonmotor symptoms* (unrelated to movement).
            </h3>
            <ul className="symptom-list">
              <li>Motor symptoms may include tremor (shaking), problems with balance, or stiffness</li>
              <li>Nonmotor symptoms may include anxiety, mood changes, or <br className="br-desktop" /> difficulty thinking</li>
            </ul>
            <div className="non-motor">*INBRIJA was not studied for nonmotor symptoms.</div>
          </div>
        </div>
        <div className="spotting-symptom-container" id="spotting-symptom">
          <div className="container-1500">
            <div className="spotting-symptom">
              <img onLoad={handleImageLoads} src="/assets/website_images/return-symptom.webp" alt="An illustrated figure sits on a bench. Lines are drawn around their hand to depict a tremor caused by Parkinson's." className="show_desktop img-fluid" />
              <img onLoad={handleImageLoads} src="/assets/website_images/return-symptom-mobile.webp" alt="An illustrated figure sits on a bench. Lines are drawn around their hand to depict a tremor caused by Parkinson's." className="show_mobile img-fluid" />
              <div className="align-left text-container ">
                <p>SYMPTOM RETURN IS FAIRLY COMMON</p>
                <h1><b>~70% of participants said they experienced at least 2 OFF periods a day</b> in an online survey of more than 3000 people conducted by the Michael J. Fox Foundation.</h1>
                <h6>About 4<span>0</span>% of the approximately 1 million people in the United States living with PD will experience the return of symptoms between regular doses of medication.</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="reasons-container" ref={experienceOff} id="experiencing-off">
          <div className="container">
            <h1>There are many reasons why a person might have an OFF period.</h1>
            <div className="reason-points">
              <img onLoad={handleImageLoads} src="/assets/images/isolation_mode.webp" alt="An illustrated image of a pill bottle" className="img-fluid" />
              <div className="reason-points-text">
                <h6>Symptoms may return even though you have taken your medication as directed</h6>
                <ul>
                  <li>The effect of your regular medication may wear off prior to your scheduled dose or <br className="br-desktop" />it may take longer than usual for your regular baseline medication to have an effect<br className="br-desktop" /> after taking a dose.</li>
                  <li>That's why Parkinson's medications may need to be added/adjusted over time</li>
                </ul>
              </div>
            </div>
            <div className="reason-points reason-points-2">
              <div className="reason-points-text">
                <h6>Parkinson's can change how well PD medicine is absorbed by your system, and so can your meals</h6>
                <ul>
                  <li>Parkinson's can slow the movement of food and pills through the gut</li>
                  <li>Meals, especially foods high in protein, can interfere with <span> oral </span> levodopa getting from your <br className="br-desktop" /> gut to your brain</li>
                  <li>There are other factors that can lower the amount of oral levodopa that enters your bloodstream and brain</li>
                </ul>
              </div>
              <img onLoad={handleImageLoads} src="/assets/website_images/Parkinson_reason.webp" alt="An illustrated image of the stomach and intestines" />
            </div>
            <div className="reason-points">
              <img onLoad={handleImageLoads} src="/assets/website_images/freezing_gait.webp" alt="An illustrated person experiencing hand tremors" />
              <div className="reason-points-text">
                <h6>People with Parkinson's report many ways that  symptom return alters their lives</h6>
                <ul>
                  <li>This can include difficulty getting around due to poor balance or freezing of gait, tremor, or slowness</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="symptom-video" id="sym-video">
          <div className="symptom-video-container">
            <div className="symptom-video-container-content mx-auto">
              <h6>Start taking control of symptom return</h6>
              <h2>talk to your doctor</h2>
              <p>
                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" className="comma">
                  <path d="M2.53548 12.1789C2.3885 12.1973 2.29664 11.7564 2.14048 11.9034C1.9292 11.5359 1.71792 11.2236 1.47909 10.9756C1.36886 10.8653 1.29537 10.7184 1.26781 10.5346C1.22188 10.2407 0.670718 10.2774 0.64316 10.0019C0.624788 9.72627 0.588044 9.44151 0.542114 9.15674C0.496184 8.74337 0.00932484 8.53209 0.000138827 8.10954C-0.00904719 7.68698 0.441068 6.97966 0.330835 6.61222C0.101185 6.0335 0.000138826 5.4364 0.0368829 4.83931C0.073627 4.23304 1.20351 3.44304 1.20351 2.85513C1.20351 2.52444 1.28618 2.24886 1.44234 2.02839C1.60769 1.80793 1.79141 1.61502 1.9935 1.44967C2.20478 1.28433 2.42525 1.12816 2.6549 0.981187C2.88455 0.834211 3.06827 0.687234 3.20606 0.540258C3.27955 0.46677 3.32548 0.384096 3.34385 0.292236C3.36222 0.200375 3.38059 0.126887 3.38059 0.0717712C3.38059 0.0166551 3.38059 -0.0017169 3.39896 0.0166551C3.40815 0.0350272 3.46327 0.117701 3.55513 0.264678C3.67455 0.411654 3.77559 0.55863 3.88582 0.705606C3.98687 0.852583 4.0971 1.01793 4.21652 1.20165C4.51966 1.45886 4.70338 1.78037 4.78605 2.16618C4.86873 2.552 4.90547 2.99292 4.90547 3.50734C4.93303 4.41676 3.83989 5.53745 3.85827 6.44687C3.88582 7.35628 5.13512 8.52291 5.13512 9.46907C5.16268 9.76302 5.18105 10.0845 5.20861 10.4244C5.23617 10.7735 5.7414 10.7919 5.69547 11.1225C5.62198 11.4165 5.52093 11.6829 5.38314 11.9217C5.24535 12.1606 3.81234 12.2984 3.69292 12.5556C3.64699 12.6291 3.26117 12.7301 3.07745 12.6382C2.89373 12.5464 2.90292 12.4178 2.85699 12.2341C2.74676 11.9034 2.89373 11.885 2.74676 11.9034" fill="#FFB423" />
                  <path d="M8.70818 11.6548C8.58876 11.7925 8.35911 11.7466 8.15702 11.7558C7.75283 11.7742 7.81714 11.223 7.58749 10.9658C7.47725 10.8556 7.40377 10.7086 7.37621 10.5249C7.33028 10.2309 6.77912 10.2677 6.75156 9.99209C6.724 9.71651 6.69644 9.43174 6.65051 9.14698C6.60458 8.74279 6.11772 8.53151 6.10854 8.10896C6.09935 7.6864 6.54947 6.97908 6.43923 6.61164C6.20958 6.02373 6.10854 5.43583 6.14528 4.82955C6.18203 4.22327 7.31191 3.43327 7.31191 2.84537C7.31191 2.51467 7.39458 2.23909 7.55074 2.01863C7.71609 1.79816 7.89981 1.60526 8.1019 1.43991C8.304 1.27456 8.52446 1.1184 8.75411 0.971421C8.98376 0.824445 9.16748 0.677469 9.30527 0.530492C9.36957 0.457004 9.42469 0.37433 9.44306 0.28247C9.47062 0.19061 9.47981 0.117122 9.47981 0.0620056C9.47981 0.00688951 9.47981 -0.0114825 9.49818 0.00688951C9.50736 0.0252615 9.56248 0.107936 9.65434 0.254912C9.77376 0.401888 9.8748 0.548864 9.98504 0.695841C10.0861 0.842817 10.1963 1.00817 10.3157 1.19189C10.6189 1.44909 10.8026 1.7706 10.8853 2.15642C10.9679 2.54223 11.0047 2.99234 11.0047 3.49758C11.0322 4.40699 9.93911 5.52769 9.95748 6.4371C9.97585 7.34652 11.4456 8.44884 11.4456 9.40418C11.464 9.69814 11.4915 10.0196 11.5099 10.3595C11.5283 10.7086 12.0427 10.727 11.9968 11.0577C11.9325 11.3516 11.8222 11.618 11.6845 11.8569C11.5467 12.0957 11.4181 12.3437 11.3078 12.6009C11.2619 12.6744 9.62678 12.6193 9.44306 12.5274C9.25934 12.4356 9.14911 12.2978 9.09399 12.1141C8.98376 11.7834 8.84597 11.6272 8.69899 11.6456" fill="#FFB423" />
                </svg>
                I've always been very open with my doctors<span> and I'm very familiar with the symptoms, so I was able to let her know that I thought I was starting to turn OFF and I told her that I just can't move forward or get up out of chairs too well. Is there anything that can help? </span>
                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" className="comma-down">
                  <path d="M9.46452 0.495865C9.6115 0.477492 9.70336 0.918421 9.85952 0.771444C10.0708 1.13888 10.2821 1.45121 10.5209 1.69923C10.6311 1.80946 10.7046 1.95644 10.7322 2.14016C10.7781 2.43411 11.3293 2.39737 11.3568 2.67295C11.3752 2.94853 11.412 3.2333 11.4579 3.51806C11.5038 3.93143 11.9907 4.14271 11.9999 4.56527C12.009 4.98783 11.5589 5.69515 11.6692 6.06259C11.8988 6.64131 11.9999 7.2384 11.9631 7.83549C11.9264 8.44177 10.7965 9.23177 10.7965 9.81967C10.7965 10.1504 10.7138 10.4259 10.5577 10.6464C10.3923 10.8669 10.2086 11.0598 10.0065 11.2251C9.79522 11.3905 9.57475 11.5466 9.3451 11.6936C9.11545 11.8406 8.93173 11.9876 8.79394 12.1345C8.72045 12.208 8.67452 12.2907 8.65615 12.3826C8.63778 12.4744 8.61941 12.5479 8.61941 12.603C8.61941 12.6581 8.61941 12.6765 8.60104 12.6581C8.59185 12.6398 8.53673 12.5571 8.44487 12.4101C8.32545 12.2632 8.22441 12.1162 8.11418 11.9692C8.01313 11.8222 7.9029 11.6569 7.78348 11.4732C7.48034 11.2159 7.29662 10.8944 7.21395 10.5086C7.13127 10.1228 7.09453 9.68188 7.09453 9.16746C7.06697 8.25805 8.16011 7.13735 8.14173 6.22794C8.11418 5.31852 6.86488 4.1519 6.86488 3.20574C6.83732 2.91179 6.81895 2.59028 6.79139 2.25039C6.76383 1.90132 6.2586 1.88295 6.30453 1.55226C6.37802 1.2583 6.47907 0.991909 6.61686 0.753072C6.75465 0.514236 8.18767 0.376445 8.30708 0.119237C8.35301 0.0457484 8.73883 -0.0552972 8.92255 0.0365636C9.10627 0.128423 9.09708 0.257027 9.14301 0.440748C9.25324 0.771444 9.10627 0.789816 9.25324 0.771444" fill="#FFB423" />
                  <path d="M3.29182 1.01907C3.41124 0.88128 3.64089 0.927209 3.84298 0.918023C4.24717 0.899651 4.18286 1.45081 4.41251 1.70802C4.52275 1.81825 4.59624 1.96523 4.62379 2.14895C4.66972 2.4429 5.22088 2.40616 5.24844 2.68174C5.276 2.95732 5.30356 3.24209 5.34949 3.52685C5.39542 3.93104 5.88228 4.14231 5.89146 4.56487C5.90065 4.98743 5.45053 5.69475 5.56077 6.06219C5.79042 6.6501 5.89146 7.238 5.85472 7.84428C5.81797 8.45056 4.68809 9.24055 4.68809 9.82846C4.68809 10.1592 4.60542 10.4347 4.44926 10.6552C4.28391 10.8757 4.10019 11.0686 3.8981 11.2339C3.696 11.3993 3.47554 11.5554 3.24589 11.7024C3.01624 11.8494 2.83252 11.9964 2.69473 12.1433C2.63043 12.2168 2.57531 12.2995 2.55694 12.3914C2.52938 12.4832 2.52019 12.5567 2.52019 12.6118C2.52019 12.6669 2.52019 12.6853 2.50182 12.6669C2.49264 12.6486 2.43752 12.5659 2.34566 12.4189C2.22624 12.2719 2.1252 12.125 2.01496 11.978C1.91392 11.831 1.80369 11.6657 1.68427 11.4819C1.38113 11.2247 1.19741 10.9032 1.11473 10.5174C1.03206 10.1316 0.995316 9.68148 0.995316 9.17625C0.967758 8.26684 2.06089 7.14614 2.04252 6.23673C2.02415 5.32731 0.554387 4.22499 0.554387 3.26964C0.536015 2.97569 0.508457 2.65418 0.490085 2.3143C0.471714 1.96523 -0.0427041 1.94686 0.00322582 1.61616C0.0675278 1.32221 0.17776 1.05581 0.31555 0.816977C0.453341 0.578141 0.581945 0.330119 0.692177 0.0729099C0.738107 -0.000578377 2.37322 0.0545384 2.55694 0.146398C2.74066 0.238258 2.85089 0.376049 2.90601 0.559768C3.01624 0.890465 3.15403 1.04663 3.30101 1.02826" fill="#FFB423" />
                </svg></p>
              <div className="ll patient-name">—Stephanie, person with Parkinson's</div>
              <div className="center">
                <button id="OFF_talk_doctor" onClick={() => navigate('/about-inbrija#talking-with-doctor')}>Talking With Your Doctor</button>
              </div>
            </div>
          </div>
        </div>
        <NavigationBar
          leftNavigation="Learn When to Use INBRIJA"
          leftNavigationid="OFF_learn_when_use"
          leftNavigationLink="/how-to-use-inbrija"
          rightNavigation="Talk to a Nurse Educator"
          rightNavigationid="OFF_talk_nurse"
          rightNavigationLink="/for-people-on-inbrija"
        />
        <div ref={myRef}> <Isi /></div>

        {show ? <IsiFrame onClick={executeScroll} /> : ''}
        <Footer code="06/23 INB13297" year="2023" title="What are Parkinson's OFF Periods? | INBRIJA® (levodopa inhalation powder)" />
      </div>
    </MainLayout>
  )
}