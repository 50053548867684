import React, { useState, useRef, useEffect } from "react";
import HeaderMenu from "../../Header/header-menu";
import Header from "../../Header/header";
import Footer from "../../Footer/Footer";
import Isi from "../../ISI/Isi";
import NavigationBar from "../../NavigationBar/NavigationBar";
import './How-to-use-inbrija.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import IsiFrame from '../../ISI/Isi-frame';
import { Helmet } from "react-helmet";
import MainLayout from "../../Layouts/Main_Layout";


export default function HowToUseInbrija() {

  const [whenINB, setWhenINB] = useState(false);
  const [learnINB, setLearnINB] = useState(false)
  const [nurse, setNurse] = useState(false)
  const [help, setHelp] = useState(false)
  const [play, setPlay] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const myRef = useRef(null)
  const learnInb = useRef(null)
  const helpful = useRef(null)
  const [show, setShow] = useState(true);

  const getChromeVersion = () => {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    if (raw) {
      return parseInt(raw[2], 10);
    }
    else {
      return 100;
    }
  }

  const executeScroll = () => {
    var element = myRef.current;
    var headerOffset = 39;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    if (getChromeVersion() > 60) {
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
    else {
      window.scrollTo(0, offsetPosition);
    }
    setShow(true)
  }
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const handleImageLoads = () => {
    // Check if all images have finished loading
    const allImagesLoaded = Array.from(document.querySelectorAll('img')).every((image) => image.complete);

    if (allImagesLoaded) {
      setImagesLoaded(true);
    }
  };

  function handleScrollCSS() {
    if (document.getElementById('how-to-use-page')) {
      const whenInbElement = document.getElementById('whenInb');
      var offsetTop1 = whenInbElement.offsetTop - 100;
      var element2 = document.getElementById('learnInb');
      var offsetTop2 = element2.offsetTop - 100;
      var elementBottom2 = document.getElementById('nurseEduSupport');
      var offsetTopBottom2 = elementBottom2.offsetTop - 100;
      var element3 = document.getElementById('nurseEduSupport');
      var offsetTop3 = element3.offsetTop - 100;
      var element4 = document.getElementById('helpful');
      var offsetTop4 = element4.offsetTop - 100;
      // case 1
      if (window.scrollY > offsetTop1) {
        document.getElementById('when-link').classList.add('submenu-style-active');
      }
      if (window.scrollY > offsetTop2 || window.scrollY < offsetTop1) {
        document.getElementById('when-link').classList.remove('submenu-style-active');
        document.getElementById('when-link').classList.add('submenu-style-not-active');
      }

      if (window.scrollY > offsetTop2) {
        document.getElementById('learn-link').classList.add('submenu-style-active');
      }
      if (window.scrollY > offsetTopBottom2 || window.scrollY < offsetTop2) {
        document.getElementById('learn-link').classList.remove('submenu-style-active');
        document.getElementById('learn-link').classList.add('submenu-style-not-active');
      }

      if (window.scrollY > offsetTop3) {
        document.getElementById('nurse-link').classList.add('submenu-style-active');
      }
      if (window.scrollY > offsetTop4 || window.scrollY < offsetTop3) {
        document.getElementById('nurse-link').classList.remove('submenu-style-active');
        document.getElementById('nurse-link').classList.add('submenu-style-not-active');
      }
      if (window.scrollY > offsetTop4) {
        document.getElementById('help-link').classList.add('submenu-style-active');
      }
      else {
        document.getElementById('help-link').classList.remove('submenu-style-active');
        document.getElementById('help-link').classList.add('submenu-style-not-active');
      }
    }
  }

  const handleVideoClick = (eventType, clickClass) => {
    window.gtag('event', eventType, {
      'event_category': 'video_start',
      'event_label': clickClass,
      'page_location': window.location.href
    });
  }

  useEffect(() => {
    document.addEventListener('click', function (e) {
      if (e.target.closest('a') || e.target.closest('button')) {
        if (typeof window !== 'undefined') {
          if (document.location.pathname.includes('/what-are-off-periods')) {
            window.gtag('event', 'what_are_off_periods_buttons');
          }
          if (document.location.pathname.includes('/about-inbrija')) {
            window.gtag('event', 'about_inbrija_buttons');
          }
          if (document.location.pathname.includes('/how-to-use-inbrija')) {
            window.gtag('event', 'how_to_use_inbrija_buttons');
          }
          if (document.location.pathname.includes('/savings-and-support')) {
            window.gtag('event', 'savings_and_support_buttons');
          }
        }
      }
    });
    handleScrollCSS();
    if (location.hash === '#when-to-use') {
      var element = document.getElementById('whenInb');
      var whenInbHeaderOffset = 39;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - whenInbHeaderOffset;

      if (getChromeVersion() > 60) {
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
      else {
        window.scrollTo(0, offsetPosition);
      }
      setWhenINB(true);
      setLearnINB(false);
      setNurse(false);
      setHelp(false)
    }

    setTimeout(() => {
      if (location.hash === '#learn-how-use-inbrija') {
        // learnInb.current.scrollIntoView({ behavior: 'smooth' });
        var element = document.getElementById('learnInb');
        var headerOffset = 20;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        if (getChromeVersion() > 60) {
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }
        else {
          window.scrollTo(0, offsetPosition);
        }
        setWhenINB(false);
        setLearnINB(true);
        setNurse(false);
        setHelp(false)
      }
    }, 500);

    setTimeout(() => {
      if (location.hash === '#peter-demo') {
        var element = document.getElementById('learnInb');
        var headerOffset = 20;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        if (getChromeVersion() > 60) {
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }
        else {
          window.scrollTo(0, offsetPosition);
        }
        setWhenINB(false);
        setLearnINB(true);
        setNurse(false);
        setHelp(false);
        setPlay(true);
        let el = document.querySelector('.demo-video');
        const video = el.querySelector('video');
        video.muted = false;
        let playbackerror = video.play();
        let trackElem = el.querySelector('track');
        if (playbackerror !== undefined) {
          playbackerror.then(function () {
            // Autoplay started!
            if (trackElem !== undefined) {
              let track1 = trackElem.track;
              track1.mode = 'hidden';
            }
          }).catch(function (error) {
            if (trackElem !== undefined) {
              let track = trackElem.track;
              track.mode = 'showing';
            }
            video.muted = true;
            video.play();
          });
        }
      }
    }, 500);

    if (location.hash === '#nurse-educator-support') {
      var nurseEduSupportElement = document.getElementById('nurseEduSupport');
      var nurseEduSupportHeaderOffset = 20;
      var nurseEduSupportElementPosition = nurseEduSupportElement.getBoundingClientRect().top;
      var nurseEduSupportOffsetPosition = nurseEduSupportElementPosition + window.pageYOffset - nurseEduSupportHeaderOffset;

      if (getChromeVersion() > 60) {
        window.scrollTo({
          top: nurseEduSupportOffsetPosition,
          behavior: "smooth"
        });
      }
      else {
        window.scrollTo(0, nurseEduSupportOffsetPosition);
      }
      setWhenINB(false);
      setLearnINB(false);
      setNurse(true);
      setHelp(false)
    }

    setTimeout(() => {
      if (location.hash === '#nurse-educator') {

        var element = document.getElementById('nurseEduSupport');
        var headerOffset = 39;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        if (getChromeVersion() > 60) {
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }
        else {
          window.scrollTo(0, offsetPosition);
        }
        setWhenINB(false);
        setLearnINB(false);
        setNurse(true);
        setHelp(false)
      }
    }, 500);

    if (location.hash === '#helpful-hints') {
      helpful.current.scrollIntoView({ behavior: 'smooth' });
      setWhenINB(false);
      setLearnINB(false);
      setNurse(false);
      setHelp(true)
    }

    if (location.pathname === '/how-to-use-inbrija' || location.pathname === '/how-to-use-inbrija/') {
      var windowWidth = window.innerWidth;
      const header = document.getElementById('submenu');

      window.addEventListener('scroll', function () {
        const headerRect = header.getBoundingClientRect();
        if (headerRect.top <= 0) {
          if (!header.classList.contains("sticky")) {
            header.classList.add('sticky');
          }
        }
        else {
          if (header.classList.contains("sticky")) {
            header.classList.remove('sticky');
          }
        }
        if (windowWidth > 767) {
          header.classList.remove('sticky');
        }
      });
    }

    const handleHashChange = () => {
      // Reset the images loaded status
      setImagesLoaded(false);
    };

    window.addEventListener('hashchange', handleHashChange);

    const calculateOffset = () => {
      const componentElement = myRef.current;

      if (myRef) {
        const offsetTop = componentElement.offsetTop;
        function handleScroll() {
          if (window.innerHeight + window.scrollY > offsetTop) {
            setShow(false);
          }
          else (
            setShow(true)
          )
        }

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("scroll", handleScrollCSS);

        return () => {
          window.removeEventListener("scroll", handleScroll);
          window.removeEventListener("scroll", handleScrollCSS);
        };
      }
    };
    calculateOffset();
    window.addEventListener('resize', calculateOffset);

    return () => {
      window.removeEventListener('resize', calculateOffset);
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [location, imagesLoaded]);

  if (imagesLoaded) {
    var header = document.getElementById('submenu');

    window.addEventListener('scroll', function () {
      var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      const offsetTop = header.offsetTop;
      if (scrollPosition > offsetTop) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    });
  }

  const handlePdf = () => {
    var ddg_file = '/pdfs/inbrija-patient-info-IFU.pdf';
    if (window.location.pathname.includes('informacion-en-espanol')) {
      ddg_file = '/pdfs/guia-de-conversacion-con-medicos.pdf';
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const myUrlWithParams = new URL(window.location.protocol + '//' + window.location.hostname + '/pdf-resources');

    myUrlWithParams.searchParams.append('file', ddg_file);
    if (urlParams.get('utm_campaign')) {
      myUrlWithParams.searchParams.append('utm_campaign', urlParams.get('utm_campaign'));
    }
    if (urlParams.get('utm_medium')) {
      myUrlWithParams.searchParams.append('utm_medium', urlParams.get('utm_medium'));
    }
    if (urlParams.get('utm_source')) {
      myUrlWithParams.searchParams.append('utm_source', urlParams.get('utm_source'));
    }
    if (urlParams.get('utm_content')) {
      myUrlWithParams.searchParams.append('utm_content', urlParams.get('utm_content'));
    }
    window.open(myUrlWithParams);
  }

  const handlePatientIfuPdf = () => {
    var ddg_file = '/pdfs/inbrija-patient-info-IFU.pdf';
    if (window.location.pathname.includes('informacion-en-espanol')) {
      ddg_file = '/pdfs/guia-de-conversacion-con-medicos.pdf';
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const myUrlWithParams = new URL(window.location.protocol + '//' + window.location.hostname + '/pdf-resources');

    myUrlWithParams.searchParams.append('file', ddg_file);
    if (urlParams.get('utm_campaign')) {
      myUrlWithParams.searchParams.append('utm_campaign', urlParams.get('utm_campaign'));
    }
    if (urlParams.get('utm_medium')) {
      myUrlWithParams.searchParams.append('utm_medium', urlParams.get('utm_medium'));
    }
    if (urlParams.get('utm_source')) {
      myUrlWithParams.searchParams.append('utm_source', urlParams.get('utm_source'));
    }
    if (urlParams.get('utm_content')) {
      myUrlWithParams.searchParams.append('utm_content', urlParams.get('utm_content'));
    }
    window.open(myUrlWithParams);
  }

  const handleInstPdf = () => {
    var ddg_file = '/pdfs/inbrija-instructions-for-use.pdf';
    if (window.location.pathname.includes('informacion-en-espanol')) {
      ddg_file = '/pdfs/guia-de-conversacion-con-medicos.pdf';
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const myUrlWithParams = new URL(window.location.protocol + '//' + window.location.hostname + '/pdf-resources');

    myUrlWithParams.searchParams.append('file', ddg_file);
    if (urlParams.get('utm_campaign')) {
      myUrlWithParams.searchParams.append('utm_campaign', urlParams.get('utm_campaign'));
    }
    if (urlParams.get('utm_medium')) {
      myUrlWithParams.searchParams.append('utm_medium', urlParams.get('utm_medium'));
    }
    if (urlParams.get('utm_source')) {
      myUrlWithParams.searchParams.append('utm_source', urlParams.get('utm_source'));
    }
    if (urlParams.get('utm_content')) {
      myUrlWithParams.searchParams.append('utm_content', urlParams.get('utm_content'));
    }
    window.open(myUrlWithParams);
  }

  const handleHelpfulPdf = () => {
    var ddg_file = '/pdfs/helpful-hints.pdf';
    if (window.location.pathname.includes('informacion-en-espanol')) {
      ddg_file = '/pdfs/guia-de-conversacion-con-medicos.pdf';
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const myUrlWithParams = new URL(window.location.protocol + '//' + window.location.hostname + '/pdf-resources');

    myUrlWithParams.searchParams.append('file', ddg_file);
    if (urlParams.get('utm_campaign')) {
      myUrlWithParams.searchParams.append('utm_campaign', urlParams.get('utm_campaign'));
    }
    if (urlParams.get('utm_medium')) {
      myUrlWithParams.searchParams.append('utm_medium', urlParams.get('utm_medium'));
    }
    if (urlParams.get('utm_source')) {
      myUrlWithParams.searchParams.append('utm_source', urlParams.get('utm_source'));
    }
    if (urlParams.get('utm_content')) {
      myUrlWithParams.searchParams.append('utm_content', urlParams.get('utm_content'));
    }
    window.open(myUrlWithParams);
  }

  const DisbableClick = () => {
    if (getChromeVersion() < 60) {
      return "disable-link-click"
    }
    else {
      return ""
    }
  }

  return (
    <MainLayout>
      <div className="how-to-use-page" id="how-to-use-page">
        <Helmet>
          <title>How to Use INBRIJA® | INBRIJA® (levodopa inhalation powder)</title>
          <meta property='og:title' content={`How to Use INBRIJA® | INBRIJA®  (levodopa inhalation powder)`} />
          <meta property='og:description' content={`Discover resources to help you learn how to use INBRIJA including helpful hints, a demonstration video, and the full Instructions For Use.`} />
          <meta property='og:site_name' content='INBRIJA.COM' />
          <meta property='og:type' content='website' />
          <meta name='description' content={`Discover resources to help you learn how to use INBRIJA including helpful hints, a demonstration video, and the full Instructions For Use.`} />
          <meta property="og:image" content="https://inbrija.com/assets/images/Inbrija-OG-Image.jpg" />
          <meta property='og:url' content='https://inbrija.com/how-to-use-inbrija' />
          <meta property='og:image:alt' content='INBRIJA logo' />
        </Helmet>
        <Header />
        <HeaderMenu>
          <div className="container-1500">
            <div className='hero-section'>
              <div className='hero-section-image'>
                <img
                  onLoad={handleImageLoads}
                  src="/assets/images/Tennis-Player-actor.webp"
                  className='img-fluid show_desktop'
                  alt={`A man holds a tennis racket over his shoulder. Words are written in the center of the racket that say "I am shaking. I am slow. I'll fight to play."`} />
                <img
                  src="/assets/images/Tennis-Player-actor.webp"
                  className='img-fluid show_mobile'
                  onLoad={handleImageLoads}
                  alt={`A man holds a tennis racket over his shoulder. Words are written in the center of the racket that say "I am shaking. I am slow. I'll fight to play."`} />
                <div className="actor_portrayal">Actor portrayal.</div>
              </div>
              <div className='hero-section-content'>
                <h1>How to use <span>INBRIJA</span></h1>
                <h4 className="">Use INBRIJA as needed when Parkinson’s disease (PD) symptoms return</h4>
              </div>
            </div>
          </div>
        </HeaderMenu>
        <div className="menu-bar-sticky-desktop">
          <div className="brush-style-2 how-to-use-brush" id="submenu">
            <div className="submenu-style">
              <Link className="text-decoration-none" to='/how-to-use-inbrija#when-to-use'> <div id="when-link" className={whenINB ? 'submenu-style-active' : 'submenu-style-not-active'}>When to Use INBRIJA</div></Link>
              <Link className="text-decoration-none" to="/how-to-use-inbrija#learn-how-use-inbrija"><div id="learn-link" className={learnINB ? 'submenu-style-active' : 'submenu-style-not-active'}>Learn How to Use INBRIJA</div></Link>
              <Link className="text-decoration-none" to="/how-to-use-inbrija#nurse-educator-support"> <div id="nurse-link" className={nurse ? 'submenu-style-active' : 'submenu-style-not-active'}>Nurse Educator Support</div></Link>
              <Link className="text-decoration-none" to="/how-to-use-inbrija#helpful-hints">   <div id="help-link" className={help ? 'submenu-style-active' : 'submenu-style-not-active'}>Helpful Hints</div></Link>
            </div>
          </div>
        </div>
        <div className="menu-bar-sticky-mobile">
          <div className="brush-style-2 mobile-header-sticky">
          </div>
        </div>
        <div className="inbrija-fight" id="whenInb">
          <h1 id="main-content">Use INBRIJA as needed* to fight Parkinson's <br className="br-desktop" /> symptoms when they return. </h1>
          <h6>*1 dose (2 capsules) per OFF period,<br className="br-mobile" /> no more than 5 doses per day.</h6>
          <p>Please see <a href="/pdfs/inbrija-patient-info-IFU.pdf" target="_blank" className="patient-information-leaflet" id="how_PIL">Patient Information Leaflet</a> and <a href="/pdfs/inbrija-instructions-for-use.pdf" target="_blank" className="IFU" id="how_IFU_1">Instructions for Use</a>.</p>
        </div>
        <div className="how-to-use-inbrija ask-peter-video-demo" ref={learnInb} id="learnInb">
          <h3>How to Use INBRIJA</h3>
          <p>Needing a few tries to get used to using your inhaler is normal when getting started with INBRIJA.<br className="br-desktop" /> Don't feel discouraged—that's normal! Peter is here to help with a step-by-step guide on how to use INBRIJA. <br className="br-desktop" />If you have more questions, check out more of Peter's videos for extra guidance about INBRIJA.</p>
          <a id="how_helpful_hints_anchor" className="link" onClick={() => navigate('/how-to-use-inbrija#helpful-hints')}>More helpful hints for using INBRIJA</a>
        </div>
        <div className="demo-video">
          {play ?
            <video src="/videos/inbrija-demonstration-video.mp4"
              controls
              autoPlay
              playsInline
              onLoad={handleImageLoads}
              muted={false}
              title="Demonstration Video"
            >
              <track src="/videos/inbrija-demonstration-video.vtt" kind="captions" srclang="en" label="English" default />
            </video>
            : <img src="/assets/website_images/peter-video-thumbnail.webp"
              className="pointer img-fluid"
              alt="Peter, an animated figure, holds the INBRIJA inhaler in his hands while standing in a kitchen."
              onLoad={handleImageLoads}
              id="how_play_peter"
              onClick={() => { setPlay(true); handleVideoClick('demo_video', 'how_play_peter') }} />}
          <div className="transcript">
            <div target="_blank" className="patient-information-leaflet"><a id="how_PIL1" href="/pdfs/inbrija-patient-info-IFU.pdf">Please see Patient Information Leaflet</a><a id="how_IFU" href="/pdfs/inbrija-patient-info-IFU.pdf"> and Instructions For Use</a></div>
            <a target="_blank" href="/videos/inbrija-demonstration-video-transcript.html" id="how_peter_transcript">Read Transcript</a>
          </div>
        </div>
        <div className="nurse-reference" id="nurseEduSupport">
          <div className="nurse-reference-left">
            <div className="nurse-reference-left-content">
              <h3>Have more questions?</h3>
              <p>Peter is here to help. Check out other Peter videos, like "When to Use INBRIJA" and "What Can I Do to Help With Cough When Using INBRIJA?"</p>
              <a href='/common-questions#peter-videos' id="how_ask_peter_button">More Just Ask Peter<sup>SM</sup> Videos</a>
            </div>
          </div>
          <div className="nurse-reference-middle">

          </div>
          <div className='nurse-reference-right'>
            <div className='nurse-support-contianer-wrap'>
              <h1>Nurse Educators are here to help</h1>
              <div className='nurse-support-contiainer-wrap-image'>
                <img
                  onLoad={handleImageLoads}
                  src='/assets/images/nurse-educator.webp' alt='Head shot of a Nurse Educator smiling' className='img-fluid' />
                <p>Actor portrayal.</p>
              </div>
              <div className='nurse-support-contiainer-wrap-content'>
                <h3>Nurse Educators are available to support you with additional training by phone or video and to answer any questions about how to use the inhaler.</h3>
                <div className="call-toll-free show_mobile"><span className="content-text">You may contact them at</span><span className='content-number'><a href="tel:1-888-887-3447" className="secondry-button">1-888-887-3447</a><span>8 <small>AM</small> to 8 <small>PM</small> ET. <br />Monday through Friday</span></span></div>
              </div>
              <div className="call-toll-free show_desktop"><span className="content-text">You may contact them at</span><span className='content-number'><a href="tel:1-888-887-3447" className="secondry-button" id="how_888">1-888-887-3447</a><span>8 <small>AM</small> to 8 <small>PM</small> ET. <br />Monday through Friday</span></span></div>
            </div>
          </div>
        </div>

        <div className="use-inbrija-as-needed" id="when-to-use">
          <div className="use-inbrija-as-needed-container">
            <div className="use-inbrija-as-needed-container-image">
              <img
                onLoad={handleImageLoads}
                src="/assets/images/MicrosoftTeams-image.webp" className='img-fluid show_desktop' alt="Close crop of a hand holding the INBRIJA inhaler." />
              <img
                onLoad={handleImageLoads}
                src="/assets/images/inhaler.webp" className='img-fluid show_mobile' alt="Close crop of a hand holding the INBRIJA inhaler." />
            </div>
            <div className="use-inbrija-as-needed-container-content">
              <h3>Use INBRIJA as needed when symptoms return in between doses of your carbidopa/levodopa medicine.</h3>
              <ul>
                <li><b>Do not orally inhale more than 1 dose</b> (2 capsules) for any OFF period. Do not take more than 5 doses (10 capsules) in a day</li>
                <li><b>Do not stop taking your daily Parkinson's medicine.</b> INBRIJA does not replace your regular carbidopa/levodopa medicine</li>
                <li><b>Do not swallow or open INBRIJA capsules.</b> Only use INBRIJA capsules with the INBRIJA inhaler</li>
                <li><b>Do not use the INBRIJA inhaler for any other medicine.</b></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="helpful-hints" ref={helpful} id="helpful">
          <div className="container">
            <h3>Using INBRIJA may take some practice at <br className="br-desktop" /> first. Below are some helpful hints.</h3>
            <div className="helpful-hints-contianer">
              <ul>
                <li>
                  <img
                    onLoad={handleImageLoads}
                    src="/assets/images/list-arrow.webp" height={49} width={49} className="img-fluid" alt="bullet icon" />
                  <div className="content">
                    <b>Stand or sit up straight and hold the inhaler level and away from your mouth.</b> Breathe out completely. Do not breathe into the mouthpiece.
                  </div>
                </li>
                <li>
                  <img
                    onLoad={handleImageLoads}
                    src="/assets/images/list-arrow.webp" className="img-fluid" alt="bullet icon" height={49} width={49} />
                  <div className="content">
                    <b>Close your lips firmly around the mouthpiece. Breathe in slowly and gently, enough to hear or feel the capsule whirl (this means you are getting your medicine).</b>
                  </div>
                </li>
                <li>
                  <img
                    onLoad={handleImageLoads}
                    src="/assets/images/list-arrow.webp" className="img-fluid" alt="bullet icon" height={49} width={49} />
                  <div className="content">
                    <b>You may take more than one breath</b> per capsule if it's easier for you
                  </div>
                </li>
                <li>
                  <img
                    onLoad={handleImageLoads}
                    src="/assets/images/list-arrow.webp" className="img-fluid" alt="bullet icon" height={49} width={49} />
                  <div className="content">
                    <b>It is quite common to cough</b> as you breathe in, but try not to. <b>If you cough out the medicine, it will reduce the amount you receive</b> in your body<span className="show_mobile">.</span>
                    <ul>
                      <li>– Some people have found that sipping liquid before and after breathing in the medicine helps with cough</li>
                      <li>– As you're breathing in, if you feel like you might cough:
                        <ul>
                          <li>Hold your breath for a few seconds</li>
                          <li>Wait until the feeling goes away</li>
                          <li>Take a sip of liquid</li>
                          <li>Breathe in again with the same capsule</li>
                        </ul>
                      </li>
                      <li>– For some people, coughing may feel like the sensation of choking</li>
                      <li>– And <b>don't forget,</b> if you cough, it's expected. Just breathe in again with the same capsule</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <img
                    onLoad={handleImageLoads}
                    src="/assets/images/list-arrow.webp" className="img-fluid" alt="bullet icon" height={49} width={49} />
                  <div className="content">
                    <b>To clean the inhaler</b>, use a new, dry cotton swab to wipe the powder off of the holes from both sides of the mouthpiece with a circular motion. (See Step 14 in <a href="/pdfs/inbrija-patient-info-IFU.pdf" className={DisbableClick()} target="_blank" id="how_IFU_2">Instructions For Use</a>)<span className="show_mobile">.</span>
                    <ul>
                      <li>– You can also use a dry tissue to wipe the outside of the mouthpiece</li>
                      <li>– <b>Do not clean any other parts</b> of the inhaler. <b>Do not rinse the mouthpiece or get the inhaler wet</b></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <img src="/assets/images/Tennis-Supporting.webp" className="tennis" alt={`A man prepares to serve a tennis ball. Writing on his arm says "I'm hitting back."`} />
        </div>

        <NavigationBar
          leftNavigation="Prepare for Discussions With Your Doctor"
          leftNavigationLink="/about-inbrija#talking-with-doctor"
          leftNavigationid="how_talk_doctor"
          rightNavigation="Learn How to Use INBRIJA"
          rightNavigationLink="/how-to-use-inbrija#learn-how-use-inbrija"
          rightNavigationid="how_learn_how_use"
        />
        <div ref={myRef}>
          <Isi />
        </div>
        {show ?
          <IsiFrame onClick={executeScroll} /> : ''}
        <Footer year="2023" code="06/23 INB13297"
          facebookLink="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.inbrija.com%2Fhow-to-use&title=Inhaled%20Delivery&description=Watch%20a%20demonstration%20video%20on%20how%20to%20use%20INBRIJA."
          twitterCode="https://twitter.com/intent/tweet?text=Inhaled%20Delivery.%20Watch%20a%20demonstration%20video%20on%20how%20to%20use%20INBRIJA.%20https://www.inbrija.com/how-to-use"
          title="How to Use INBRIJA® | INBRIJA®  (levodopa inhalation powder)"
        />
      </div>
    </MainLayout>
  )
}