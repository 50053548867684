import React, { useState, useEffect, useRef } from "react";
import Header from '../../Header/header';
import HeaderMenu from '../../Header/header-menu';
import Footer from '../../Footer/Footer';
import ISI from '../../ISI/Isi';
import IsiFrame from '../../ISI/Isi-frame';
import './contact-us.css';
import { Helmet } from "react-helmet";
import MainLayout from "../../Layouts/Main_Layout";

export default function ContactUs() {
  const myRef = useRef(null)
  const [show, setShow] = useState(true)
  const executeScroll = () => {
    myRef.current.scrollIntoView();
    setShow(true)
  }

  useEffect(() => {
    const calculateOffset = () => {
      const componentElement = myRef.current;

      if (myRef) {
        const offsetTop = componentElement.offsetTop;
        function handleScroll() {
          if (window.innerHeight + window.scrollY > offsetTop) {
            setShow(false);
          }
          else (
            setShow(true)
          )
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }
    };
    calculateOffset();
    window.addEventListener('resize', calculateOffset);

    return () => {
      window.removeEventListener('resize', calculateOffset);
    };
  }, []);

  return (
    <MainLayout>
      <div className="contact-us-page">
        <Helmet>
          <title>Contact Us | INBRIJA® (levodopa inhalation powder)</title>
          <meta property='og:title' content={`Contact Us | INBRIJA® (levodopa inhalation powder)`} />
          <meta property='og:description' content={`For more assistance or additional information regarding INBRIJA® (levodopa inhalation powder), contact Acorda Therapeutics, Inc.`} />
          <meta property='og:site_name' content='INBRIJA.COM' />
          <meta property='og:type' content='website' />
          <meta name='description' content={`For more assistance or additional information regarding INBRIJA® (levodopa inhalation powder), contact Acorda Therapeutics, Inc.`} />
          <meta property="og:image" content="https://inbrija.com/assets/images/Inbrija-OG-Image.jpg" />
          <meta property='og:url' content='https://inbrija.com/contact-us' />
          <meta property='og:image:alt' content='INBRIJA logo' />
        </Helmet>
        <Header />
        <HeaderMenu>
          <div className="hero-section">
            <div className="container">
              <h1>Contact us</h1>
            </div>
          </div>
        </HeaderMenu>
        <div className="brush-style"></div>
        <div className="contact-intro-section">
          <div className="container">
            <h2>Have questions?</h2>
          </div>
        </div>
        <div className="contact-content-section" id="main-content">
          <div className="container-1500">
            <div className="container">
              <div className="telephone-number">
                <img src="/assets/images/call-icon.webp" alt="Phone and chat icon" className="call-icon-img" />
                <div className="telephone">
                  <span>Phone</span>
                  <a href="tel:1-833-INBRIJA">1-833-INBRIJA <span>(1-833-462-7452)</span></a>
                </div>
              </div>
              <div className="more-information">
                <p>To report any side effect or product complaint, please call <a href="tel:1-800-367-5109" target="_blank">1-800-367-5109</a> or email <a href="mailto:acordamc@eversana.com">acordamc@eversana.com</a>.</p>
              </div>
              <div className="address-box">
                <h3>Merz Therapeutics U.S.</h3>
                <address>
                  <p>Merz Pharmaceuticals, LLC</p>
                  <p>6601 Six Forks Road, Suite 430</p>
                  <p>Raleigh, North Carolina 27615</p>
                  <p>United States</p>
                </address>
              </div>
            </div>
          </div>
        </div>
        <div ref={myRef}>
          <ISI />
        </div>
        {show ? <IsiFrame onClick={executeScroll} /> : ''}
        <Footer code="06/23 INB13297" year="2023" title="Contact Us | INBRIJA® (levodopa inhalation powder)"
          des="Learn about INBRIJA, Prescription Support Services, and downloadable resources. Watch a video about a person who uses INBRIJA. See Indication, Important Safety Information, and Patient Information Leaflet."
        />
      </div>
    </MainLayout>
  )
}