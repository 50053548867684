import React, { useState, useRef, useEffect } from "react";
import Header from "../../Header/header";
import HeaderMenu from "../../Header/header-menu";
import ISI from "../../ISI/Isi";
import IsiFrame from "../../ISI/Isi-frame";
import Footer from "../../Footer/Footer";
import "./common-question.css";
import AllQuestions from "./questions";
import NavigationBar from "../../NavigationBar/NavigationBar";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MainLayout from "../../Layouts/Main_Layout";

const sideScroll = (element, speed, distance, step) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step * 3;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

const PeterModal = ({ show, children, onHide }) => {
  const ModalwithHash = () => {
    let el = document.querySelector(".modal.show");
    const video = el.querySelector("video");
    video.muted = false;
    let playbackerror = video.play();
    let trackElem = el.querySelector("track");
    if (playbackerror !== undefined) {
      playbackerror
        .then(function () {
          // Autoplay started!
          if (trackElem !== undefined) {
            let track1 = trackElem.track;
            track1.mode = "hidden";
          }
        })
        .catch(function (error) {
          if (trackElem !== undefined) {
            let track = trackElem.track;
            track.mode = "showing";
          }
          video.muted = true;
          video.play();
        });
    }
  };
  return (
    <Modal show={show} onHide={onHide} dialogClassName="peter-video-modal" centered onEntered={ModalwithHash}>
      <div className="modal-close">
        <div className="close-modal" onClick={onHide}>
          <div className="close-modal-left "></div>
          <div className="close-modal-right"></div>
        </div>
      </div>
      <Modal.Body className="video-modal-alignment peter-video-modal-background">
        <div className="">
          <LazyLoadImage src="/assets/images/peter-modal-logo.webp" className="peter-logo" />
        </div>
        {children}
      </Modal.Body>
    </Modal>
  );
};

const PeterModalWithURL = ({ show, muted, handleClose, src, transcript, executeScroll, VideoTrack, title }) => {
  const [showCaptions, setShowCaptions] = useState(false);
  const videoRef = useRef(null);
  const handleVolumeChange = () => {
    const isMuted = videoRef.current.muted;
    setShowCaptions(isMuted);
  };

  const getChromeVersion = () => {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    if (raw) {
      return parseInt(raw[2], 10);
    } else {
      return 100;
    }
  };

  const handlePatientIfuPdf = () => {
    var ddg_file = "/pdfs/inbrija-patient-info-IFU.pdf";
    if (window.location.pathname.includes("informacion-en-espanol")) {
      ddg_file = "/pdfs/guia-de-conversacion-con-medicos.pdf";
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const myUrlWithParams = new URL(window.location.protocol + "//" + window.location.hostname + "/pdf-resources");

    myUrlWithParams.searchParams.append("file", ddg_file);
    if (urlParams.get("utm_campaign")) {
      myUrlWithParams.searchParams.append("utm_campaign", urlParams.get("utm_campaign"));
    }
    if (urlParams.get("utm_medium")) {
      myUrlWithParams.searchParams.append("utm_medium", urlParams.get("utm_medium"));
    }
    if (urlParams.get("utm_source")) {
      myUrlWithParams.searchParams.append("utm_source", urlParams.get("utm_source"));
    }
    if (urlParams.get("utm_content")) {
      myUrlWithParams.searchParams.append("utm_content", urlParams.get("utm_content"));
    }
    window.open(myUrlWithParams);
  };

  const ModalwithHash = () => {
    let el = document.querySelector(".modal.show");
    const video = el.querySelector("video");
    video.muted = false;
    let playbackerror = video.play();
    let trackElem = el.querySelector("track");
    if (playbackerror !== undefined) {
      playbackerror
        .then(function () {
          // Autoplay started!
          if (trackElem !== undefined) {
            let track1 = trackElem.track;
            track1.mode = "hidden";
          }
        })
        .catch(function (error) {
          if (trackElem !== undefined) {
            let track = trackElem.track;
            track.mode = "showing";
          }
          video.muted = true;
          video.play();
        });
    }
  };

  const DisbableClick = () => {
    if (getChromeVersion() < 60) {
      return "disable-link-click";
    } else {
      return "";
    }
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="peter-video-modal" centered onEntered={ModalwithHash}>
      <div className="modal-close">
        <div className="close-modal" onClick={handleClose}>
          <div className="close-modal-left "></div>
          <div className="close-modal-right"></div>
        </div>
      </div>
      <Modal.Body className="video-modal-alignment peter-video-modal-background">
        <div className="">
          <LazyLoadImage src="/assets/images/peter-modal-logo.webp" className="peter-logo" />
        </div>
        <div className="peter-multiple-videos-container">
          <div className="peter-swipe"></div>
          <video src={src} controls autoPlay playsInline muted={muted} title={title} className="peter-multiple-video" ref={videoRef} onVolumeChange={handleVolumeChange}>
            {showCaptions ? <track src={VideoTrack} kind="captions" srclang="en" label="English" default /> : ""}
          </video>
          <div className="peter-swipe"></div>
        </div>
        <div className="peter-video-instructions">
          <a href="/pdfs/inbrija-patient-info-IFU.pdf" target="_blank" className={`peter-video-inst ${DisbableClick()}`}>
            Please see Patient Information Leaflet and Instructions For Use.
          </a>
          <a target="_blank" href={transcript} className="peter-video-inst" rel="noreferrer">
            Read Transcript
          </a>
        </div>
        <div className="peter-video-inst-safety" onClick={executeScroll}>
          Please see Important Safety Information
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default function CommonQuestion() {
  const contentWrapper = useRef(null);
  const location = useLocation();
  const myRef = useRef(null);
  const [cough, setCough] = useState(false);
  const [show, setShow] = useState(true);
  const [clean, setClean] = useState(false);
  const [whenInb, setWhenInb] = useState(false);
  const [whirl, setWhirl] = useState(false);
  const [fast, setFast] = useState(false);
  const [video, setVideo] = useState(false);
  const [videoState, setVideoState] = useState(false);
  const [videoStateNo, setVideoStateNo] = useState(false);
  const navigate = useNavigate();

  const executeScroll = () => {
    myRef.current.scrollIntoView();
    setShow(true);
  };
  const peterfast = useRef(null);
  const peterVideoRef = useRef(null);
  const peterFast = () => {
    peterfast.current.scrollIntoView({ behavior: "smooth" });
    setFast(true);
  };
  const vidRef = useRef(null);
  const vidRefMobile = useRef(null);

  const handlePauseVideo = () => {
    if (vidRef.current) {
      vidRef.current.pause();
    }
    setVideo(true);
  };
  const handlePlayVideo = () => {
    vidRef.current.play();
    setVideo(false);
  };

  const handlePauseVideoMobile = () => {
    if (vidRefMobile.current) {
      vidRefMobile.current.pause();
    }
    setVideo(true);
  };
  const handlePlayVideoMobile = () => {
    vidRefMobile.current.play();
    setVideo(false);
  };
  const getChromeVersion = () => {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    if (raw) {
      return parseInt(raw[2], 10);
    } else {
      return 100;
    }
  };

  const DisbableClick = () => {
    if (getChromeVersion() < 60) {
      return "disable-link-click";
    } else {
      return "";
    }
  };
  useEffect(() => {
    switch (location.hash) {
      case "#peter-how-fast":
      case "#fast":
        setFast(true);
        break;
      case "#peter-videos":
        peterVideoRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "#peter-cough":
      case "#cough":
        setCough(true);
        break;
      case "#peter-when-use":
      case "#when":
        setWhenInb(true);
        break;
      case "#peter-whirl":
      case "#whirl":
        setWhirl(true);
        break;
      case "#peter-cleaning":
      case "#clean":
        setClean(true);
        break;
      default:
        break;
    }
    setTimeout(() => {
      const headerOffset = 39;
      if (location.hash === "#full-ISI") {
        var isiElement = myRef.current;
        var isiElementPosition = isiElement.getBoundingClientRect().top;
        var isiOffsetPosition = isiElementPosition + window.pageYOffset - headerOffset;
        if (getChromeVersion() > 60) {
          window.scrollTo({
            top: isiOffsetPosition,
            behavior: "smooth",
          });
        } else {
          window.scrollTo(0, isiOffsetPosition);
        }
      }
      if (location.hash === "#faqs") {
        var faqsElement = document.getElementById("faqs");
        var faqsElementPosition = faqsElement.getBoundingClientRect().top;
        var faqsOffsetPosition = faqsElementPosition + window.pageYOffset - headerOffset;
        if (getChromeVersion() > 60) {
          window.scrollTo({
            top: faqsOffsetPosition,
            behavior: "smooth",
          });
        } else {
          window.scrollTo(0, faqsOffsetPosition);
        }
      }
      let el = document.querySelector(".ask-peter-slider");
      const video = el.querySelector("video");
      video.muted = true;
      video.play();
    }, 1000);

    const calculateOffset = () => {
      const componentElement = myRef.current;

      if (myRef) {
        const offsetTop = componentElement.offsetTop;
        function handleScroll() {
          if (window.innerHeight + window.scrollY > offsetTop) {
            setShow(false);
          } else setShow(true);
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }
    };
    calculateOffset();
    window.addEventListener("resize", calculateOffset);

    return () => {
      window.removeEventListener("resize", calculateOffset);
    };
  }, [location]);

  const handlePatientIfuPdf = () => {
    var ddg_file = "/pdfs/inbrija-patient-info-IFU.pdf";
    if (window.location.pathname.includes("informacion-en-espanol")) {
      ddg_file = "/pdfs/guia-de-conversacion-con-medicos.pdf";
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const myUrlWithParams = new URL(window.location.protocol + "//" + window.location.hostname + "/pdf-resources");

    myUrlWithParams.searchParams.append("file", ddg_file);
    if (urlParams.get("utm_campaign")) {
      myUrlWithParams.searchParams.append("utm_campaign", urlParams.get("utm_campaign"));
    }
    if (urlParams.get("utm_medium")) {
      myUrlWithParams.searchParams.append("utm_medium", urlParams.get("utm_medium"));
    }
    if (urlParams.get("utm_source")) {
      myUrlWithParams.searchParams.append("utm_source", urlParams.get("utm_source"));
    }
    if (urlParams.get("utm_content")) {
      myUrlWithParams.searchParams.append("utm_content", urlParams.get("utm_content"));
    }
    window.open(myUrlWithParams);
  };

  const handleVideoClick = (eventType, clickClass) => {
    window.gtag("event", eventType, { event_category: "video_start", event_label: clickClass, page_location: window.location.href });
  };

  return (
    <MainLayout>
      <div className="common-questions-page">
        <Helmet>
          <title>Common Questions | INBRIJA® (levodopa inhalation powder)</title>
          <meta property="og:title" content={`Common Questions | INBRIJA® (levodopa inhalation powder)`} />
          <meta property="og:description" content={`Find helpful questions and answers about INBRIJA.`} />
          <meta property="og:site_name" content="INBRIJA.COM" />
          <meta property="og:type" content="website" />
          <meta name="description" content={`Find helpful questions and answers about INBRIJA.`} />
          <meta property="og:image" content="https://inbrija.com/assets/images/Inbrija-OG-Image.jpg" />
          <meta property="og:url" content="https://inbrija.com/common-questions" />
          <meta property="og:image:alt" content="INBRIJA logo" />
        </Helmet>
        <Header />
        <HeaderMenu>
          <div className="container ">
            <div className="inbrija-stories-header">
              <h1>
                Common questions about <span>INBRIJA</span> and Parkinson's disease (PD)
              </h1>
              <h6>What do you need to know?</h6>
            </div>
          </div>
        </HeaderMenu>

        <div className="brush-style"></div>
        <div className="container">
          <div className="ask-peter" ref={peterfast}>
            <div className="saving-heading">
              The Just Ask Peter<sup className="sm-black">SM </sup> Video Guides
            </div>
            <div className="saving-text">
              We know that learning to use INBRIJA can be a little intimidating, so we created a set of videos to help. With some
              <br className="common-questions-br" /> practice, we think you'll find INBRIJA to be a real help in your fight against returning PD symptoms.
            </div>
            <div className="ask-peter-slider">
              <div className="center">
                <div className="peter-video hidden-mobile">
                  <div className="peter-video-content">
                    <div className="peter-play">
                      <LazyLoadImage src="/assets/images/peter-logo 1.webp" alt="Peter logo" className="img-fluid peter-logo-img" />
                      <br />
                      {video ? <LazyLoadImage src="/assets/images/peter-play.png" alt="Play video" className="img-fluid peter-video-logo-pause-desktop" onClick={() => handlePlayVideo()} /> : <LazyLoadImage src="/assets/images/peter-pause.png" alt="Pause video" className="peter-video-logo-pause-desktop img-fluid" onClick={() => handlePauseVideo()} />}
                    </div>
                    <div className="peter-text-container" ref={peterVideoRef}>
                      <div className="peter-video-content-text">Hi, I'm Peter!</div>
                      <div className="peter-video-content-text">
                        I'm here to answer your questions about <br /> INBRIJA<span className="superscript">®</span>. If it's your first time using INBRIJA,
                        <br /> please visit the{" "}
                        <span>
                          {" "}
                          <a href="/how-to-use-inbrija">How to Use INBRIJA</a>
                        </span>{" "}
                        page and <br /> watch the demonstration video.
                      </div>
                    </div>
                  </div>
                  <video src="/videos/peter-loop.mp4" autoPlay muted playsInline ref={vidRef} title="JustAskPeter video thumbnail" className="img-fluid peter-video"></video>
                </div>
                <div className="peter-video hidden-desktop">
                  <div className="peter-video-content">
                    <div className="peter-play">
                      <LazyLoadImage src="/assets/images/peter-logo 1.webp" alt="Peter logo" className="img-fluid peter-video-logo" />
                      <br />
                      {video ? <LazyLoadImage src="/assets/images/play.webp" alt="Play video" className="img-fluid peter-video-logo-pause" onClick={() => handlePlayVideoMobile()} /> : <LazyLoadImage src="/assets/images/play-peter-video.webp" alt="Pause video" className="peter-video-logo-pause img-fluid" onClick={() => handlePauseVideoMobile()} />}
                    </div>
                  </div>
                  <video src="/videos/peter-loop.mp4" autoPlay playsInline muted ref={vidRefMobile} title="JustAskPeter video thumbnail" className="img-fluid peter-video"></video>
                </div>
              </div>
              <div className="flex-justify-center ask-peter-slider-main">
                <div
                  className="align-self-center left-arrow-slider"
                  onClick={() => {
                    sideScroll(contentWrapper.current, 25, 100, -10);
                  }}>
                  <svg width="23" height="38" viewBox="0 0 23 38" fill="none" xmlns="http://www.w3.org/2000/svg" className="show_desktop">
                    <path opacity="0.3" d="M19.5 3L3.5 19L19.5 35" stroke="#7C6991" strokeWidth="5.33333" strokeLinecap="round" strokeLinejoin="round" className="pointer" />
                  </svg>
                  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" className="show_mobile">
                    <rect width="46" height="46" rx="23" transform="matrix(-1 0 0 1 46 0)" fill="#7C6991" />
                    <path d="M25.5 31.25L17.5 23.25L25.5 14.75" stroke="#EAE7F1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className="contentWrapper ask-peter-width" ref={contentWrapper}>
                  <div className="ask-peter-slider-item">
                    <LazyLoadImage src="/assets/images/peter-dosing-thumb.webp" className="pointer show_desktop peter-dosing" alt="Peter demonstrating how to use INBRIJA" 
                      onClick={() => {
                        setVideoState(true);
                        setVideoStateNo(0);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_when");
                      }} />
                    <LazyLoadImage src="/assets/images/peter-dosing-thumb-mobile.webp" className="pointer show_mobile peter-dosing" alt="Peter demonstrating how to use INBRIJA" 
                      onClick={() => {
                        setWhenInb(true);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_when");
                      }} />
                    <div className="center">
                      <div className="inbrija-msg pointer show_desktop" 
                        onClick={() => {
                          setVideoState(true);
                          setVideoStateNo(0);
                          handleVideoClick("just_ask_peter_videos", "common_watch_peter_when");
                        }}>
                        When should I use INBRIJA?
                      </div>
                      <div className="inbrija-msg pointer show_mobile" 
                        onClick={() => {
                          setWhenInb(true);
                          handleVideoClick("just_ask_peter_videos", "common_watch_peter_when");
                        }}>
                        When should I use INBRIJA?
                      </div>
                    </div>
                  </div>
                  <div className="ask-peter-slider-item">
                    <LazyLoadImage src="/assets/images/peter-whirl-thumb.webp" alt="Peter demonstrating how to use INBRIJA" className="pointer show_desktop peter-whirl"
                      onClick={() => {
                        setVideoState(true);
                        setVideoStateNo(1);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_whirl");
                      }}
                    />
                    <LazyLoadImage src="/assets/images/peter-whirl-thumb-mobile.webp" alt="Peter demonstrating how to use INBRIJA" className="pointer show_mobile peter-whirl"
                      onClick={() => {
                        setWhirl(true);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_whirl");
                      }}
                    />
                    <div className="inbrija-msg pointer show_desktop"
                      onClick={() => {
                        setVideoState(true);
                        setVideoStateNo(1);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_whirl");
                      }}>
                      What if I don't hear or feel the whirl when I am using the inhaler?
                    </div>
                    <div className="inbrija-msg pointer show_mobile"
                      onClick={() => {
                        setWhirl(true);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_whirl");
                      }}>
                      What if I don't hear or feel the whirl when I am using the inhaler?
                    </div>
                  </div>
                  <div className="ask-peter-slider-item">
                    <LazyLoadImage src="/assets/images/peter-cough-thumb.webp" alt="Peter demonstrating how to use INBRIJA" className="pointer show_desktop peter-cough"
                      onClick={() => {
                        setVideoState(true);
                        setVideoStateNo(2);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_cough");
                      }}
                    />
                    <LazyLoadImage src="/assets/images/peter-cough-thumb-mobile.webp" alt="Peter demonstrating how to use INBRIJA" className="pointer show_mobile peter-cough"
                      onClick={() => {
                        setCough(true);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_cough");
                      }}
                    />
                    <div className="inbrija-msg pointer show_desktop"
                      onClick={() => {
                        setVideoState(true);
                        setVideoStateNo(2);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_cough");
                      }}>
                      What can I do to help with cough when using INBRIJA?
                    </div>
                    <div className="inbrija-msg pointer show_mobile"
                      onClick={() => {
                        setCough(true);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_cough");
                      }}>
                      What can I do to help with cough when using INBRIJA?
                    </div>
                  </div>
                  <div className="ask-peter-slider-item">
                    <LazyLoadImage src="/assets/images/how-fast-thumb.webp" alt="Peter demonstrating how to use INBRIJA" className="pointer show_desktop peter-how-fast"
                      onClick={() => {
                        setVideoState(true);
                        setVideoStateNo(3);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_fast");
                      }}
                    />
                    <LazyLoadImage src="/assets/images/how-fast-thumb-mobile.webp" alt="Peter demonstrating how to use INBRIJA" className="pointer show_mobile peter-how-fast"
                      onClick={() => {
                        setFast(true);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_fast");
                      }}
                    />
                    <div className="inbrija-msg pointer show_desktop"
                      onClick={() => {
                        setVideoState(true);
                        setVideoStateNo(3);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_fast");
                      }}>
                      How fast does INBRIJA start to work?
                    </div>
                    <div className="inbrija-msg pointer show_mobile"
                      onClick={() => {
                        setFast(true);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_fast");
                      }}>
                      How fast does INBRIJA start to work?
                    </div>
                  </div>
                  <div className="ask-peter-slider-item">
                    <LazyLoadImage src="/assets/images/clean-thumb.webp" alt="Peter demonstrating how to use INBRIJA" className="pointer show_desktop peter-cleaning"
                      onClick={() => {
                        setVideoState(true);
                        setVideoStateNo(4);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_clean");
                      }}
                    />
                    <LazyLoadImage src="/assets/images/clean-thumb-mobile.webp" alt="Peter demonstrating how to use INBRIJA" className="pointer show_mobile peter-cleaning"
                      onClick={() => {
                        setClean(true);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_clean");
                      }}
                    />
                    <div className="inbrija-msg pointer show_desktop"
                      onClick={() => {
                        setVideoState(true);
                        setVideoStateNo(4);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_clean");
                      }}>
                      How should I clean my inhaler?
                    </div>
                    <div className="inbrija-msg pointer show_mobile"
                      onClick={() => {
                        setClean(true);
                        handleVideoClick("just_ask_peter_videos", "common_watch_peter_clean");
                      }}>
                      How should I clean my inhaler?
                    </div>
                  </div>
                </div>
                <div className="align-self-center right-arrow-slider"
                  onClick={() => {
                    sideScroll(contentWrapper.current, 25, 100, 10);
                  }}>
                  <svg width="23" height="38" viewBox="0 0 23 38" fill="none" xmlns="http://www.w3.org/2000/svg" className="show_desktop">
                    <path opacity="0.3" d="M3.5 35L19.5 19L3.5 3" stroke="#7C6991" strokeWidth="5.33333" strokeLinecap="round" strokeLinejoin="round" className="pointer" />
                  </svg>
                  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" className="show_mobile">
                    <rect width="46" height="46" rx="23" fill="#7C6991" />
                    <path d="M20.5 31.25L28.5 23.25L20.5 14.75" stroke="#EAE7F1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div id="faqs">
            <AllQuestions isiScroll={executeScroll} />
          </div>
        </div>
        <div className="still-have-ques">
          <div className="still-have-ques-text">
            <h1>Still have questions? </h1>
            <h2>Call INBRIJA Prescription Support Services</h2>
            <div className="call-toll-free">
              <span>Call toll-free</span>
              <a href="tel://1-888-887-3447" className="primary-button">1-888-887-3447</a>
            </div>
            <div className="small-call">8 <small>AM</small> to 8 <small>PM</small> ET Monday through Friday</div>
          </div>
        </div>

        <div className="still-have-ques-mobile show_mobile">
          <LazyLoadImage src="/assets/images/inhaler.webp" className="img-fluid show_mobile" alt="Close crop of a hand holding the INBRIJA inhaler." />

          <div className="still-have-ques-text-mobile">
            <h1>Still have questions? </h1>
            <h2>Call INBRIJA Prescription Support Services</h2>
            <div className="call-toll-free">
              <span>Call toll-free</span>
              <a href="tel://1-888-887-3447" className="primary-button">1-888-887-3447</a>
            </div>
            <div className="small-call">8 <small>AM</small> to 8 <small>PM</small> ET Monday through Friday</div>
          </div>
        </div>
        <NavigationBar leftNavigation="Get Free Tools and Resources" rightNavigation="See More About Our Savings Program" leftNavigationLink="/tools-and-resources" rightNavigationLink="/savings-and-support#savings-programs" />
        <div ref={myRef}>
          <ISI />
        </div>
        {show ? <IsiFrame onClick={executeScroll} /> : ""}
        <Footer year="2023" code="06/23 INB13297" facebookLink="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.inbrija.com%2Fcommon-questions&title=Common%20questions%20about%20INBRIJA&description=Find%20helpful%20questions%20and%20answers%20about%20INBRIJA." twitterCode="https://twitter.com/intent/tweet?text=Common%20questions%20about%20INBRIJA.%20Find%20helpful%20questions%20and%20answers%20about%20INBRIJA.%20https://www.inbrija.com/common-questions" />
        <PeterModalWithURL
          title="When should I use INBRIJA?"
          show={whenInb}
          muted={location.hash === "#peter-when-use" || location.hash === "#when" ? true : false}
          src="/videos/inbrija-when-to-use-video.mp4"
          handleClose={() => {
            setWhenInb(false);
            navigate("/common-questions");
          }}
          VideoTrack="/videos/inbrija-how-fast-video.vtt"
          transcript="/videos/inbrija-when-to-use-video-transcript.html"
          executeScroll={() => {
            setWhenInb(false);
            executeScroll();
          }}
        />
        <PeterModalWithURL
          title="What if I don't hear or feel the whirl when I am using the inhaler?"
          show={whirl}
          muted={location.hash === "#peter-whirl" || location.hash === "#whirl" ? true : false}
          src="/videos/inbrija-whirl-video.mp4"
          handleClose={() => {
            setWhirl(false);
            navigate("/common-questions");
          }}
          VideoTrack="/videos/inbrija-whirl-video.vtt"
          transcript="/videos/inbrija-whirl-video-transcript.html"
          executeScroll={() => {
            setWhirl(false);
            executeScroll();
          }}
        />
        <PeterModalWithURL
          title="What can I do to help with cough when using INBRIJA?"
          show={cough}
          muted={location.hash === "#peter-cough" || location.hash === "#cough" ? true : false}
          src="/videos/inbrija-cough-video.mp4"
          handleClose={() => {
            setCough(false);
            navigate("/common-questions");
          }}
          VideoTrack="/videos/inbrija-cough-video.vtt"
          transcript="/videos/inbrija-cough-video-transcript.html"
          buttonclick="/common-questions#peter-cough"
          executeScroll={() => {
            setCough(false);
            executeScroll();
          }}
        />
        <PeterModalWithURL
          title="How fast does INBRIJA start to work?"
          show={fast}
          muted={location.hash === "#peter-how-fast" || location.hash === "#fast" ? true : false}
          src="/videos/inbrija-how-fast-video.mp4"
          handleClose={() => {
            setFast(false);
            navigate("/common-questions");
          }}
          VideoTrack="/videos/inbrija-how-fast-video.vtt"
          transcript="/videos/inbrija-how-fast-transcript.html"
          executeScroll={() => {
            setFast(false);
            executeScroll();
          }}
        />
        <PeterModalWithURL
          title="How should I clean my inhaler?"
          show={clean}
          muted={location.hash === "#peter-cleaning" || location.hash === "#clean" ? true : false}
          src="/videos/inbrija-clean-video.mp4"
          handleClose={() => {
            setClean(false);
            navigate("/common-questions");
          }}
          VideoTrack="/videos/inbrija-clean-video.vtt"
          transcript="/videos/inbrija-clean-inhaler-video-transcript.html"
          executeScroll={() => {
            setClean(false);
            executeScroll();
          }}
        />
        <PeterModal className="peter-modal" show={videoState} onHide={() => setVideoState(false)}>
          <div className="peter-video-title">
            <p>{videoStateNo === 0 ? "When should I use INBRIJA?" : videoStateNo === 1 ? `What if I don't hear or feel the whirl when I am using the inhaler?` : videoStateNo === 2 ? "What can I do to help with cough when using INBRIJA?" : videoStateNo === 3 ? "How fast does INBRIJA start to work?" : videoStateNo === 4 ? "How should I clean my inhaler?" : ""}</p>
            <p>(Video {videoStateNo === 0 ? "1" : videoStateNo === 1 ? "2" : videoStateNo === 2 ? "3" : videoStateNo === 3 ? "4" : videoStateNo === 4 ? "5" : ""} of 5)</p>
          </div>
          <div className="peter-multiple-videos-container">
            {videoStateNo === 0 ? <div className="peter-swipe"></div> : <LazyLoadImage src="/assets/images/swipe-left.webp" className={videoStateNo === 1 ? "peter-swipe pointer peter-dosing" : videoStateNo === 2 ? "peter-swipe pointer peter-whirl" : videoStateNo === 3 ? "peter-swipe pointer peter-cough" : videoStateNo === 4 ? "peter-swipe pointer peter-how-fast" : "peter-swipe pointer "} onClick={() => setVideoStateNo(videoStateNo - 1)} />}
            <video src={videoStateNo === 0 ? "/videos/inbrija-when-to-use-video.mp4" : videoStateNo === 1 ? "/videos/inbrija-whirl-video.mp4" : videoStateNo === 2 ? "/videos/inbrija-cough-video.mp4" : videoStateNo === 3 ? "/videos/inbrija-how-fast-video.mp4" : videoStateNo === 4 ? "/videos/inbrija-clean-video.mp4" : ""} controls autoPlay playsInline className="peter-multiple-video" title={videoStateNo === 0 ? "When should I use INBRIJA?" : videoStateNo === 1 ? `What if I don't hear or feel the whirl when I am using the inhaler?` : videoStateNo === 2 ? `What can I do to help with cough when using INBRIJA?` : videoStateNo === 3 ? `How fast does INBRIJA start to work?` : videoStateNo === 4 ? "How should I clean my inhaler?" : ""}>
              <track src={videoStateNo === 0 ? "/videos/inbrija-how-fast-video.vtt" : videoStateNo === 1 ? "/videos/inbrija-whirl-video.vtt" : videoStateNo === 2 ? "/videos/inbrija-cough-video.vtt" : videoStateNo === 3 ? "/videos/inbrija-how-fast-video.vtt" : videoStateNo === 4 ? "/videos/inbrija-clean-video.vtt" : ""} kind="captions" srclang="en" label="English" default />
            </video>
            {videoStateNo === 4 ? <div className="peter-swipe"></div> : <LazyLoadImage src="/assets/images/swipe-right.webp" className={videoStateNo === 0 ? "peter-swipe pointer peter-whirl" : videoStateNo === 1 ? "peter-swipe pointer peter-cough" : videoStateNo === 2 ? "peter-swipe pointer peter-how-fast" : videoStateNo === 3 ? "peter-swipe pointer peter-cleaning" : "peter-swipe pointer"} onClick={() => setVideoStateNo(videoStateNo + 1)} />}
          </div>
          <div className="peter-video-instructions">
            <a href="/pdfs/inbrija-patient-info-IFU.pdf" target="_blank" rel="noreferrer" className={`peter-video-inst ${DisbableClick()}`}>
              Please see Patient Information Leaflet and Instructions For Use.
            </a>
            <a target="_blank" href={videoStateNo === 0 ? "/videos/inbrija-when-to-use-video-transcript.html" : videoStateNo === 1 ? "/videos/inbrija-whirl-video-transcript.html" : videoStateNo === 2 ? "/videos/inbrija-cough-video-transcript.html" : videoStateNo === 3 ? "/videos/inbrija-how-fast-transcript.html" : videoStateNo === 4 ? "/videos/inbrija-clean-inhaler-video-transcript.html" : ""} className="peter-video-inst" rel="noopener">
              Read Transcript
            </a>
          </div>
          <div
            className="peter-video-inst-safety"
            onClick={() => {
              setVideoState(false);
              executeScroll();
            }}>
            Please see Important Safety Information
          </div>
        </PeterModal>
      </div>
    </MainLayout>
  );
}
