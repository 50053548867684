import React, { useState, useRef, useEffect } from 'react';
import HeaderMenu from "../../Header/header-menu";
import Header from "../../Header/header";
import Footer from "../../Footer/Footer";
import Isi from "../../ISI/Isi";
import IsiFrame from "../../ISI/Isi-frame";
import NavigationBar from "../../NavigationBar/NavigationBar";
import './PeopleOnIbrija.css';
import Brochure from '../../Brochure/Brochure';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MainLayout from '../../Layouts/Main_Layout';


export default function PeopleOnInbrija() {
  const myRef = useRef(null)
  const [show, setShow] = useState(true)
  const navigate = useNavigate();

  const executeScroll = () => {
    myRef.current.scrollIntoView();
    setShow(true)
  }

  let location = useLocation();

  const handleVideoClick = (eventType, clickClass) => {
    window.gtag('event', eventType, {
      'event_category': 'video_start',
      // 'event_category': 'video_play',
      'event_label': clickClass,
      'page_location': window.location.href
    });
  }

  const getChromeVersion = () => {
    var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    if (raw) {
      return parseInt(raw[2], 10);
    }
    else {
      return 100;
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (location.hash === '#full-ISI') {
        // TalkDr.current.scrollIntoView({ behavior: 'smooth', });
        var element = myRef.current;
        var headerOffset = 39;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        if (getChromeVersion() > 60) {
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }
        else {
          window.scrollTo(0, offsetPosition);
        }
      }
    }, 1000);
    const calculateOffset = () => {
      const componentElement = myRef.current;

      if (myRef) {
        const offsetTop = componentElement.offsetTop;
        function handleScroll() {
          if (window.innerHeight + window.scrollY > offsetTop) {
            setShow(false);
          }
          else (
            setShow(true)
          )
        }

        window.addEventListener("scroll", handleScroll);


        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }
    };
    calculateOffset();
    window.addEventListener('resize', calculateOffset);

    return () => {
      window.removeEventListener('resize', calculateOffset);
    };
  }, [location]);

  const handlePatientIfuPdf = () => {
    var ddg_file = '/pdfs/inbrija-patient-info-IFU.pdf';
    if (window.location.pathname.includes('informacion-en-espanol')) {
      ddg_file = '/pdfs/guia-de-conversacion-con-medicos.pdf';
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const myUrlWithParams = new URL(window.location.protocol + '//' + window.location.hostname + '/pdf-resources');
    myUrlWithParams.searchParams.append('file', ddg_file);
    if (urlParams.get('utm_campaign')) {
      myUrlWithParams.searchParams.append('utm_campaign', urlParams.get('utm_campaign'));
    }
    if (urlParams.get('utm_medium')) {
      myUrlWithParams.searchParams.append('utm_medium', urlParams.get('utm_medium'));
    }
    if (urlParams.get('utm_source')) {
      myUrlWithParams.searchParams.append('utm_source', urlParams.get('utm_source'));
    }
    if (urlParams.get('utm_content')) {
      myUrlWithParams.searchParams.append('utm_content', urlParams.get('utm_content'));
    }
    window.open(myUrlWithParams);
  }

  const handleHelpfulPdf = () => {
    var ddg_file = '/pdfs/helpful-hints.pdf';
    if (window.location.pathname.includes('informacion-en-espanol')) {
      ddg_file = '/pdfs/guia-de-conversacion-con-medicos.pdf';
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const myUrlWithParams = new URL(window.location.protocol + '//' + window.location.hostname + '/pdf-resources');

    myUrlWithParams.searchParams.append('file', ddg_file);
    if (urlParams.get('utm_campaign')) {
      myUrlWithParams.searchParams.append('utm_campaign', urlParams.get('utm_campaign'));
    }
    if (urlParams.get('utm_medium')) {
      myUrlWithParams.searchParams.append('utm_medium', urlParams.get('utm_medium'));
    }
    if (urlParams.get('utm_source')) {
      myUrlWithParams.searchParams.append('utm_source', urlParams.get('utm_source'));
    }
    if (urlParams.get('utm_content')) {
      myUrlWithParams.searchParams.append('utm_content', urlParams.get('utm_content'));
    }
    window.open(myUrlWithParams);
  }

  const DisbableClick = () => {
    if (getChromeVersion() < 60) {
      return "disable-link-click"
    }
    else {
      return ""
    }
  }

  return (
    <MainLayout>
      <div className='people-on-inbrija'>
        <Helmet>
          <title>Information for People on INBRIJA® (levodopa inhalation powder) | INBRIJA® (levodopa inhalation powder)</title>
          <meta property='og:title' content={`Information for People on INBRIJA® (levodopa inhalation powder) | INBRIJA® (levodopa inhalation powder)`} />
          <meta property='og:description' content={`View resources and get guidance on using INBRIJA, storage information, and answers to frequently asked questions. See Indication, Important Safety Information, and Patient Information Leaflet.`} />
          <meta property='og:site_name' content='INBRIJA.COM' />
          <meta property='og:type' content='website' />
          <meta name='description' content={`View resources and get guidance on using INBRIJA, storage information, and answers to frequently asked questions. See Indication, Important Safety Information, and Patient Information Leaflet.`} />
          <meta property="og:image" content="https://inbrija.com/assets/images/Inbrija-OG-Image.jpg" />
          <meta property='og:url' content='https://inbrija.com/for-people-on-inbrija' />
          <meta property='og:image:alt' content='INBRIJA logo' />
        </Helmet>
        <Header />
        <HeaderMenu>
          <div className="container ">
            <div className="inbrija-stories-header">
              <h1>
                Key resources in<span> YOUR FIGHT AGAINST RETURNING SYMPTOMS</span> with INBRIJA
              </h1>
              <h6>
                The people, the resources, and the guidance you might find useful in your fight against returning Parkinson's disease (PD) symptoms.
              </h6>
            </div>
          </div>
        </HeaderMenu>
        <div className="brush-style"> </div>

        <div className='still-have-ques' id="main-content">
          <div className='still-have-ques-text'>
            <LazyLoadImage src='/assets/images/nurse-educator.webp' alt='Head shot of a nurse educator smiling' className='img-fluid show_mobile' />
            <div className='still-have-ques-text-actor show_mobile'>Actor portrayal.</div>
            <h1><b>Nurse Educators are on call</b> to help  you get the most from INBRIJA</h1>
            <div className='call-toll-free'><span>You may contact them at</span><a href="tel://1-888-887-3447" className='primary-button' id='resources_888'>1-888-887-3447</a></div>
            <div className='small-call'>8 <small>AM</small> to 8 <small>PM</small> ET Monday through Friday</div>
            <div className='actor-potyaral'>Actor portrayal.</div>
          </div>
        </div>

        <div className='notes-section'>
          <p>It may take several tries for you to become familiar with inhaling INBRIJA. It's important not to be discouraged based on your first few tries.</p>
          <p>Nurse Educators are available to support you with additional training by phone or video and to answer questions about how to use the inhaler.</p>
        </div>
        <div className='resources-section'>
          <div className='resources-container-title'>
            <h2 className='resources-section-heading container'>Explore these resources designed to offer support while fighting returning symptoms with INBRIJA.</h2>
          </div>
          <div className='resources-container-download  show_desktop'>
            <div className='people-brochure'>
              <Brochure src="/assets/images/brochure-ask-peter.webp" src1="/assets/images/brochure-ask-peter.webp" alt="Close shot of animated figure Peter using the INBRIJA inhaler.">
                <div className='brochure-heading-content'>
                  <div className='brochure-heading'>How to Use INBRIJA</div>
                  <div className='brochure-content'>Visit the How to Use INBRIJA page for instructions and tips on using INBRIJA.</div>
                  <button className='brochure-button-inbrija' onClick={() => navigate('/how-to-use-inbrija')} id='resources_learn_how_use'>Learn More</button>
                </div>
              </Brochure>
              <Brochure src="/assets/images/brochure-demonstation.webp" src1="/assets/images/brochure-demonstation.webp" alt="Head shot of animated figure Peter, standing in his kitchen.">
                <div className='brochure-heading-content'>
                  <div className='brochure-heading'>INBRIJA Demonstration Video</div>
                  <div className='brochure-content'>A step-by-step training video for using the INBRIJA inhaler.</div>
                  <button className='brochure-button-inbrija ask-peter-video-demo' onClick={() => { navigate('/how-to-use-inbrija#peter-demo'); handleVideoClick('demo_video', 'resources_watch_peter_demo') }}>Watch Video</button>
                </div>
              </Brochure>
              <Brochure src="/assets/images/brochure-helpful.webp" src1="/assets/images/brochure-helpful.webp" alt="Screenshot of Helpful Hints document cover">
                <div className='brochure-heading-content'>
                  <div className='brochure-heading'>Helpful Hints for Using INBRIJA</div>
                  <div className='brochure-content'>Using INBRIJA may take some practice. Use these helpful hints to make your experience as smooth as possible.</div>
                  <a className={`show_mobile text-decoration-none ${DisbableClick()}`} href="/pdfs/helpful-hints.pdf" target='_blank' id='resources_helpful_hints'>
                    <button className='brochure-button-inbrija show_mobile'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='show_mobile'>Download</svg>
                    </button>
                  </a>
                  <a href="/pdfs/helpful-hints.pdf" target='_blank' className={`text-decoration-none ${DisbableClick()}`} id='resources_helpful_hints'>
                    <button className='helpful-hints-button static'>Download</button>
                  </a>
                </div>
              </Brochure>
              <Brochure src="/assets/images/brochure-common-ques.webp" src1="/assets/images/brochure-common-ques-1.webp" alt="Thumbnails for various animated Peter videos that showcase how to use the INBRIJA inhaler.">
                <div className='brochure-heading-content'>
                  <div className='brochure-heading'>Get Answers</div>
                  <div className='brochure-content'>Have questions about using INBRIJA to fight returning symptoms? Find answers to frequently asked questions and helpful Just Ask Peter<span className='peter-sm'>SM</span> video clips on the Common Questions page.</div>
                  <button className='brochure-button-inbrija' onClick={() => navigate('/common-questions')} id='resources_common_questions'>Common Questions</button>
                </div>
              </Brochure>
            </div>

            <div className='saving-container'>
              <h3 className='show_desktop'>Storing, cleaning, and disposing of your INBRIJA inhaler</h3>
              <h3 className='show_mobile'>Storage and Cleaning of Your INBRIJA Inhaler</h3>
              <div className='container'>
                <div className='saving-items'>
                  <div className='saving-item'>
                    <LazyLoadImage src="/assets/images/store-inhaler.webp" alt="thermometer" className='align-baseline' />
                    <div className='saving-text'>
                      <p>Store your inhaler and capsules at room temperature (68 °F to 77 °F <br className='br-desktop' />[20 °C to 25 °C]), in a dry place. </p>
                      <p className='note'>That means no freezing or refrigerating.</p>
                    </div>
                  </div>
                  <div className='saving-item'>
                    <LazyLoadImage src="/assets/images/inhaler-capsule.webp" alt='pill blister' className='align-baseline' />
                    <div className='saving-text'>
                      <p>Keep the capsules inside their foil&nbsp;(blister) packages until just before you are ready to use them. Do not preload (store capsules inside) the inhaler for a future dose.</p>
                      <p className='note'>Keep INBRIJA out of the reach of children.</p>
                    </div>
                  </div>
                  <div className='saving-item'>
                    <LazyLoadImage src="/assets/images/clean-inhaler.webp" alt="tissue box" className='align-baseline' />
                    <div className='saving-text'>
                      <p>It is normal for some powder to remain in or on the inhaler. You can clean the inside and outside of the mouthpiece using a circular motion with a dry cotton swab.</p>
                      <p className='note'>Make sure you don't get the inhaler wet. You may also use a dry tissue to wipe the outside of the mouthpiece as needed.</p>
                    </div>
                  </div>
                  <div className='saving-item'>
                    <LazyLoadImage src="/assets/images/trash-inhaler.webp" alt='trash can' className='align-baseline' />
                    <div className='saving-text'>
                      <p>When all the capsules in a carton are gone, throw the inhaler in the trash. Each carton of capsules includes an inhaler, so you'll get a new inhaler with each new medication delivery.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='resources-container-download  show_mobile'>
            <div className='people-brochure'>
              <Brochure>
                <div className='brochure-heading-content'>
                  <div className='brochure-heading'>How to Use INBRIJA</div>
                  <div className='brochure-content'>Visit the How to Use INBRIJA page for instructions and tips on using INBRIJA.</div>
                  <button className='brochure-button' onClick={() => navigate('/how-to-use-inbrija')} id='resources_learn_how_use'>Learn More</button>
                </div>
              </Brochure>
              <Brochure>
                <div className='brochure-heading-content'>
                  <div className='brochure-heading'>INBRIJA Demonstration Video</div>
                  <div className='brochure-content'>A step-by-step training video for using the INBRIJA inhaler.</div>
                  <button className='brochure-button ask-peter-video-demo' onClick={() => { navigate('/how-to-use-inbrija#peter-demo'); handleVideoClick('demo_video', 'resources_watch_peter_demo') }}>Watch Video</button>
                </div>
              </Brochure>
              <Brochure>
                <div className='brochure-heading-content'>
                  <div className='brochure-heading'>Helpful Hints for Using INBRIJA</div>
                  <div className='brochure-content'>Using INBRIJA may take some practice. Use these helpful hints to make your experience as smooth as possible.</div>

                  <a href="/pdfs/helpful-hints.pdf" target='_blank' className={`text-decoration-none ${DisbableClick()}`} id='resources_helpful_hints'>
                    <button className='helpful-hints-button static'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className=''>
                      <path d="M14 10L14 12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14L3.33333 14C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667L2 10" stroke="#AE9BC3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.66602 6.66699L7.99935 10.0003L11.3327 6.66699" stroke="#AE9BC3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8 10L8 2" stroke="#AE9BC3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                      Download</button>
                  </a>
                </div>
              </Brochure>
              <Brochure>
                <div className='brochure-heading-content'>
                  <div className='brochure-heading'>Get Answers</div>
                  <div className='brochure-content'>Have questions about using INBRIJA to fight returning symptoms? Find answers to frequently asked questions and helpful Just Ask Peter<span className='peter-sm'>SM</span> video clips on the Common Questions page.</div>
                  <button className='brochure-button' onClick={() => navigate('/common-questions')} id='resources_common_questions'>Common Questions</button>
                </div>
              </Brochure>
            </div>

            <div className='saving-container'>
              <h3 className='show_desktop'>Storing, cleaning, and disposing of your INBRIJA inhaler</h3>
              <h3 className='show_mobile'>Storage and Cleaning of Your INBRIJA Inhaler</h3>
              <div className='container'>
                <div className='saving-items'>
                  <div className='saving-item'>
                    <LazyLoadImage src="/assets/images/store-inhaler.webp" alt="thermometer" className='align-baseline' />
                    <div className='saving-text'>
                      <p>Store your inhaler and capsules at room temperature (68 °F to 77 °F <br className='br-desktop' />[20 °C to 25 °C]), in a dry place. </p>
                      <p className='note'>That means no freezing or refrigerating.</p>
                    </div>
                  </div>
                  <div className='saving-item'>
                    <LazyLoadImage src="/assets/images/inhaler-capsule.webp" alt='pill capsule' className='align-baseline' />
                    <div className='saving-text'>
                      <p>Keep the capsules inside their foil&nbsp;(blister) packages until just before you are ready to use them. Do not preload (store capsules inside) the inhaler for a future dose.</p>
                      <p className='note show_desktop'>As for all medicines, keep INBRIJA out of the reach of children.</p>
                      <p className='note show_mobile'>Keep INBRIJA out of the reach of children.</p>
                    </div>
                  </div>
                  <div className='saving-item'>
                    <LazyLoadImage src="/assets/images/clean-inhaler.webp" alt="tissue box" className='align-baseline' />
                    <div className='saving-text'>
                      <p>It is normal for some powder to remain in or on the inhaler. You can clean the inside and outside of the mouthpiece using a circular motion with a dry cotton swab.</p>
                      <p className='note'>Make sure you don't get the inhaler wet. You may also use a dry tissue to wipe the outside of the mouthpiece as needed.</p>
                    </div>
                  </div>
                  <div className='saving-item'>
                    <LazyLoadImage src="/assets/images/trash-inhaler.webp" alt='trash can' className='align-baseline' />
                    <div className='saving-text'>
                      <p>When all the capsules in a carton are gone, throw the inhaler in the trash. Each carton of capsules includes an inhaler, so you'll get a new inhaler with each new medication delivery.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className='container' />
        <div className='org-help-container'>
          <div className='org-help-container-title'>
            <div className='container'>
              <h2 className='show_desktop'>Connect With Advocacy Groups</h2>
              <h2 className='show_mobile'>Connect With Advocacy Groups</h2>
            </div>
          </div>
          <div className='org-help-container-list'>
            <div className='container'>
              <ul>
                <li>
                  <a href="https://www.apdaparkinson.org/" target="_blank" className="text-link | fw-semi-bold txt-sapphire" rel="noreferrer" data-link-type="external">American Parkinson's Disease Association</a>
                  <p>
                    <a href="tel:1-800-708-7644" className="txt-base">1-800-223-2732</a>
                  </p>
                </li>
                <li>
                  <a href="https://www.michaeljfox.org/" target="_blank" className="text-link | fw-semi-bold txt-sapphire" rel="noreferrer" data-link-type="external">The Michael J. Fox Foundation for Parkinson's Research</a>
                  <p>
                    <a href="tel:1-800-708-7644" className="txt-base">1-800-708-7644</a>
                  </p>
                </li>
                <li>
                  <a href="https://caregiveraction.org/" target="_blank" className="text-link | fw-semi-bold txt-sapphire" rel="noreferrer" data-link-type="external">Caregiver Action Network</a>
                  <p><a href="tel:1-202-772-5050" className="txt-base">1-202-772-5050</a></p>
                </li>
              </ul>
              <ul>
                <li>
                  <a href='https://www.davisphinneyfoundation.org/' target="_blank" rel="noreferrer" id='resources_DP'>Davis Phinney Foundation for Parkinson's</a>
                  <p><a href="tel:1-866-358-0285" className="text-base">1-866-358-0285</a></p>
                </li>
                <li>
                  <a href="https://www.parkinson.org/" target="_blank" className="text-link | fw-semi-bold txt-sapphire" rel="noreferrer" data-link-type="external">Parkinson's Foundation</a>
                  <p>
                    FL: 200 SE 1st Street, Ste 800, Miami, FL 33131<br />
                    NY: 1359 Broadway, Ste 1509, New York, NY 10018<br />
                    <a href="tel:1-800-473-4636" className="d-block d-md-none txt-base">1-800-4PD-INFO (473-4636)</a>
                    Email: <a href="mailto:contact@parkinson.org" className="txt-sapphire text-link">contact@parkinson.org</a>
                  </p>
                </li>
                <li>
                  <a href='https://www.pmdalliance.org/' target="_blank" rel="noreferrer" id='resources_PMD'>Parkinson & Movement Disorder Alliance</a>
                  <p><a href="tel:8002560966">800-256-0966</a></p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='tips-container'>
          <div className='container'>
            <center>
              <img src='/assets/images/tips-background.webp' alt='An illustration of a person walking' /></center>
            <h2>Tips to Manage the Return of Your PD Symptoms</h2>
            <div className='max-width-645'>
              <h5>Pace yourself during the day</h5>
              <p>You can help keep fatigue at bay by saving your strength and taking frequent breaks. Making sure you have some downtime here and there will help you relax and recharge.</p>
            </div>
            <div className='max-width-780'>
              <h5>Know your rhythms</h5>
              <p>Are you a morning person? A night owl? Somewhere in between? Schedule errands and activities to make the best use of your daily rhythms.</p>
            </div>
            <div className='max-width-100'>
              <h5>Get creative with exercise</h5>
              <p>Improve motor function and engage your mind at the same time with skill-based exercises. Try walking a course with a specific time goal. Make sure to talk to your doctor and care team before starting a new exercise routine.</p>
            </div>
            <div className='max-width-100'>
              <h5>Make shopping simple*</h5>
              <p>Conserve your strength for when you need it by making shopping as easy for yourself as possible. Here are some ideas:</p>
              <ul>
                <li>Try shopping online and automated deliveries for products you routinely buy</li>
                <li>Bring a family member or friend along for company when you go shopping—having company can make shopping less of a chore and more of a fun, social activity</li>
                <li>Streamline your trips. When you go to the store, try to map out your trips so you can visit several stores located close to one another</li>
              </ul>
              <p>By following these tips, you can save your energy and enjoy quality time with the people you care about.</p>
            </div>
            <div className='max-width-100'>
              <h5>Make eating out more enjoyable*</h5>
              <p>Heading out to break bread with family and friends? Set yourself up to succeed at having a great time by:</p>
              <ul>
                <li>Avoiding crowds—go before or after the usual meal rush</li>
                <li>Planning your order ahead of time—that way, you can focus on enjoying yourself at the table</li>
              </ul>
            </div>
            <div className='max-width-100'>
              <h5>Remember to take your INBRIJA with you</h5>
              <p>Easy to carry in your purse, pocket, or bag, the inhaler and blister capsules can go with you almost anywhere. Make sure to keep  INBRIJA dry and at room temperature.</p>
            </div>
            <div className='max-width-100'>
              <p><a target='_blank' href="/pdfs/inbrija-patient-info-IFU.pdf" className={DisbableClick()} id='resources_PIL'>Please see Patient Information Leaflet and Instructions For Use</a>.</p>
            </div>
            <div className='max-width-100'>
              <p className='note'>*Tell your healthcare provider if you experience unusual uncontrollable urges such as binge eating and shopping.</p>
            </div>
          </div>
        </div>
        <div className=''>
          <NavigationBar
            leftNavigation="Learn How to Use INBRIJA"
            leftNavigationLink="/how-to-use-inbrija"
            leftNavigationid="resources_learn_how_use"
            rightNavigation="Check Out Helpful Hints for Using INBRIJA"
            rightNavigationLink="/how-to-use-inbrija#helpful-hints"
            rightNavigationid="resources_helpful_hints_2"
          />
        </div>
        <div ref={myRef}>
          <Isi />
        </div>
        {show ?
          <IsiFrame onClick={executeScroll} /> : ''}
        <Footer code="06/23 INB13297" year="2023"
          facebookLink="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.inbrija.com%2Fpeople-on-inbrija&title=Tips%20and%20information%20about%20INBRIJA&description=Resources%20to%20help%20you."
          twitterCode="https://twitter.com/intent/tweet?text=Tips%20and%20information%20about%20INBRIJA.%20Resources%20to%20help%20you.%20https://www.inbrija.com/people-on-inbrija" />
      </div>
    </MainLayout>
  )
}